import { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Card from '../../../components/cards/Card';
import Circle from '../../../components/figures/Circle';
import H4 from '../../../components/typography/H4';
import Paragraph from '../../../components/typography/Paragraph';
import InputText from '../../../components/typography/InputText';
import { truncateText, getDescMaxLength, isEnglish } from '../../../utils/helpers';

export default function CaseItem({ caseInfo }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [maxDescLength, setMaxDescLength] = useState(45);
  const [isActive, setIsActive] = useState(0);
  const itemContainerRef = useRef(null);

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const isCurrentLanguageEnglish = isEnglish(language);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const caseItemWidth = itemContainerRef?.current?.clientWidth;
    if (caseItemWidth) {
      getDescMaxLength(caseItemWidth, setMaxDescLength);
    }
  }, [windowWidth, itemContainerRef?.current?.clientWidth]);

  const handleMouseEnter = () => {
    setIsActive(1);
  };

  const handleMouseLeave = () => {
    setIsActive(0);
  };

  const { slug } = caseInfo;

  return (
    <div className="case-studies-item" ref={itemContainerRef}>
      <div className="circle-case">
        <Circle styles={{ $position: 'relative', $active: isActive }} />
      </div>

      {isCurrentLanguageEnglish && (
        <Link className="projects__item-page" to={`/case-studies/${slug}`}>
          <CaseItemCard
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            maxDescLength={maxDescLength}
            caseInfo={caseInfo}
            isCurrentLanguageEnglish={isCurrentLanguageEnglish}
            t={t}
          />
        </Link>
      )}

      {!isCurrentLanguageEnglish && (
        <CaseItemCard
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
          maxDescLength={maxDescLength}
          caseInfo={caseInfo}
          isCurrentLanguageEnglish={isCurrentLanguageEnglish}
          t={t}
        />
      )}
    </div>
  );
}

function CaseItemCard({
  handleMouseEnter,
  handleMouseLeave,
  maxDescLength,
  caseInfo,
  isCurrentLanguageEnglish,
  t,
}) {
  const { imgLow, imgHigh, title, description } = caseInfo;

  return (
    <Card
      className="case-card"
      $width="100%"
      $height={isCurrentLanguageEnglish ? '100%' : 'max-content!important'}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="case-studies-images">
        <img
          src={`/img/case-studies/${imgLow}`}
          alt={title + ' app'}
          className="case-studies-image"
        />
        <img
          src={`/img/case-studies/${imgHigh}`}
          alt={title}
          className="case-studies-image"
        />
      </div>

      <div className="case-studies-tags">
        <InputText $color="#F3F8FA" className="case-tag">
          {t('mobileAppDevelopment')}
        </InputText>
        <InputText $color="#F3F8FA" className="case-tag">
          {t('design')}
        </InputText>
      </div>

      <H4 $align="left" $margin="0 0 16px 0" className="case-card-title">
        {title}
      </H4>
      <Paragraph
        $color="#F3F8FA"
        $mediaSmallFontSize="12px"
        className="case-studies-description"
      >
        {isCurrentLanguageEnglish
          ? truncateText(description, maxDescLength)
          : description}
      </Paragraph>
    </Card>
  );
}
