import React from "react";
import styled from "styled-components";

const TextLargeStyled = styled.div`
  text-align: ${(props) => props.$align || "center"};

  /* New/Text Large */
  font-family: "Inter-Regular", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  color: ${(props) => props.$color || "#E1F1FF"};

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export default function TextLarge(props) {
  return <TextLargeStyled {...props}>{props.children}</TextLargeStyled>;
}
