import { Link, useLocation } from 'react-router-dom';
import ButtonText from '../../components/typography/ButtonText';
import { useTranslation } from 'react-i18next';

export default function Navigation() {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  return (
    <nav className="nav footer__nav">
      <ul className="list-unstyled row">
        <li className="col-6 p-0">
          <ul className="list-unstyled">
            <NavLinkItem to="/" name="Home" pathname={pathname} t={t} />
            <NavLinkItem
              to="/case-studies"
              name="Case Studies"
              pathname={pathname}
              t={t}
            />
            <NavLinkItem to="/services" name="Services" pathname={pathname} t={t} />
            <NavLinkItem to="/careers" name="Careers" pathname={pathname} t={t} />
          </ul>
        </li>
        <li className="col-6 p-0">
          <ul className="list-unstyled">
            <NavLinkItem to="/contact" name="Contact" pathname={pathname} t={t} />
            <NavLinkItem to="/our-team" name="Our Team" pathname={pathname} t={t} />
            <NavLinkItem
              to="/sitemap.xml"
              name="Sitemap"
              pathname={pathname}
              t={t}
            />
            <NavLinkItem
              to="/privacy-policy"
              name="Privacy Policy"
              pathname={pathname}
              t={t}
            />
          </ul>
        </li>
      </ul>
    </nav>
  );
}

function NavLinkItem({ to, name, pathname, t }) {
  const isCurrentLinkActive = checkLink(pathname, name);

  if (name === 'Sitemap') {
    return (
      <li>
        <a href="/sitemap.xml">
          <ButtonText $align="left" $color="#011730">
            {t(name)}
          </ButtonText>
        </a>
      </li>
    );
  }

  return (
    <li>
      <Link to={to} className="nav__link">
        <ButtonText
          $align="left"
          $color="#011730"
          $hoverColor="#2A69B4"
          $isActive={isCurrentLinkActive}
        >
          {t(name)}
        </ButtonText>
      </Link>
    </li>
  );
}

function checkLink(pathname, name) {
  const isHomeLinkActive =
    name === 'Home' && (pathname === '/' || pathname === '/ua');
  const isTeamLinkActive = name === 'Our team' && pathname === '/our-team';
  const isCaseLinkActive = name === 'Case studies' && pathname === '/case-studies';

  return (
    pathname.includes(name?.toLocaleLowerCase()) ||
    isHomeLinkActive ||
    isTeamLinkActive ||
    isCaseLinkActive
  );
}
