import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ItemCard from '../../../../components/cards/ItemCard';
import ArrowButton from '../../../../components/buttons/ArrowButton';
import ContentImage from './ContentImage';

import H4 from '../../../../components/typography/H4';
import InputText from '../../../../components/typography/InputText';

import { Constants } from '../../constants';

import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default function TabsContainerMobile() {
  const {
    i18n: { language },
  } = useTranslation();
  const innovationTabsData =
    Constants[`INNOVATION_TABS_DATA_${language.toUpperCase()}`];

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  return (
    <div className="tabs-container-mobile">
      <div className="tabs-container-mobile-wrapper">
        <Swiper
          speed={600}
          centeredSlides={true}
          centeredSlidesBounds={true}
          slidesPerView={1}
          spaceBetween={30}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          loop
        >
          {innovationTabsData.map((innovation) => {
            return (
              <SwiperSlide key={innovation.id}>
                <ItemCard
                  $padding="24px 16px"
                  $flexDirection="column"
                  $justifyContent="flex-start"
                  $gap="10px"
                  className="innovation-mobile-card"
                >
                  <div className="mobile-innovations-header">
                    <ContentImage activeTab={innovation.id} />
                    <H4 $useMedia>{innovation.name}</H4>
                  </div>

                  <div className="mobile-innovations-text">
                    <InputText
                      $align="left"
                      $useMedia
                      $mediaSmallFontSize="16px"
                      $mediaSmallLineHeihgt="21px"
                    >
                      {innovation.title} {innovation.text}
                    </InputText>
                  </div>
                </ItemCard>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>

      <div className="swiper-controls">
        <ArrowButton direction="left" ref={navigationPrevRef} />
        <ArrowButton direction="right" ref={navigationNextRef} />
      </div>
    </div>
  );
}
