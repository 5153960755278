import React from "react";
import styled, { css } from "styled-components";

const InputTextStyled = styled.div`
  text-align: ${(props) => props.$align || "center"};
  color: ${(props) => props.$color || "#E1F1FF"};

  /* New/Inputs */
  font-family: "Inter-Medium", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  transition: all 0.25s ease;

  &:hover {
    color: ${(props) => props.$hoverColor};
  }

  /* small screen */
  ${(props) =>
    props.$useMedia &&
    css`
      @media (max-width: 767px) {
        font-size: ${(props) => props.$mediaSmallFontSize || "14px"};
        line-height: ${(props) => props.$mediaSmallLineHeihgt || "20px"};
      }
			@media (max-width: 560px) {
        font-size: ${(props) => props.$mediaSmallFontSize || "12px"};
        line-height: ${(props) => props.$mediaSmallLineHeihgt || "20px"};
      }
    `}
`;

export default function InputText(props) {
  return <InputTextStyled {...props}>{props.children}</InputTextStyled>;
}
