import ClientsReviews from './ClientsReviews';
import ClientsTitle from './ClientsTitle';

export default function Clients() {
  return (
    <section className="container-fluid clients" id="clients-section-view">
      <ClientsTitle />
      <ClientsReviews />
    </section>
  );
}
