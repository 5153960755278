import { useTranslation } from 'react-i18next';
import IndustriesItem from './IndustriesItem';
import H2 from '../../../components/typography/H2';
import Paragraph from '../../../components/typography/Paragraph';
import { Constants } from '../constants';

import { register } from 'swiper/element/bundle';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Grid } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/grid';

register();

export default function Industries() {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const descriptionText = t('homePageIndustriesDescription');
  const descriptionTop = descriptionText.split('<br />')[0];
  const descriptionBottom = descriptionText.split('<br />')[1];

  const industries = Constants[`INDUSTRIES_${language.toUpperCase()}`];

  return (
    <section className="industries-container" id="services-section-view">
      <div className="section__head">
        <H2>{t('homePageIndustriesTitle')}</H2>
        <Paragraph $align="center">
          {descriptionTop}
          <br />
          {descriptionBottom}
        </Paragraph>
      </div>

      <div className="industries__grid">
        {industries.map((industry, i) => (
          <IndustriesItem
            key={industry.title + i}
            industry={industry}
            width="204px"
            height="209px"
          />
        ))}
      </div>

      <div className="swiper industries__grid-mobile">
        <Swiper
          slidesPerView={2}
          initialSlide={1}
          grid={{
            rows: 2,
          }}
          spaceBetween={12}
          modules={[Grid]}
          centeredSlides={true}
          centeredSlidesBounds={true}
        >
          {industries.map((industry) => (
            <SwiperSlide key={industry.title}>
              <IndustriesItem industry={industry} width="204px" height="209px" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
}
