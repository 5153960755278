import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import Button from '../../../components/buttons/Button';
import H5 from '../../../components/typography/H5';
import InputText from '../../../components/typography/InputText';
import { ReactComponent as AiImage } from '../../../assets/navigation/ai.svg';
import { ReactComponent as AiImageHovered } from '../../../assets/navigation/ai-hovered.svg';

import { isEnglish } from '../../../utils/helpers';

const NAVIGATION_NAMES = {
  en: [
    ['Home', 'Home'],
    ['Our team', 'Our team'],
    ['AI', 'AI'],
    ['Case studies', 'Case studies'],
    ['Services', 'Services'],
    ['Careers', 'Careers'],
    ['Contact', 'Contact'],
  ],
  ua: [
    ['Home', 'Головна'],
    ['Our team', 'Наша Команда'],
    ['Case studies', 'Приклади Робіт'],
    ['Clients', 'Наші Клієнти'],
  ],
};

export default function Navigation({ services, isVisible, toggleNav }) {
  const [servicesHovered, setServicesHovered] = useState(false);
  const toggleServicesHover = (state) => setServicesHovered(state);

  const [aiHovered, setAiHovered] = useState(false);
  const toggleAiHover = (state) => setAiHovered(state);

  const { pathname } = useLocation();

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const handleServicesClick = () => {
    toggleNav();
    toggleServicesHover(false);
  };

  const navClasses = cn('nav', 'header__nav', { show: isVisible });

  return (
    <nav className={navClasses} id="nav">
      <ul className="list-unstyled d-md-flex">
        {NAVIGATION_NAMES[language].map(([navKey, name]) => {
          const isLinkActive = checkLink(pathname, navKey);

          if (navKey === 'Services') {
            return (
              <li
                onMouseEnter={() => toggleServicesHover(true)}
                onMouseLeave={() => toggleServicesHover(false)}
                key={navKey}
              >
                <Link
                  to={getLink(navKey, language)}
                  className="nav__link"
                  data-page="Services"
                  onClick={handleServicesClick}
                >
                  <H5
                    $color="#F3F8FA"
                    $hoverColor="#50C5EA"
                    $isActive={isLinkActive}
                  >
                    {name}
                  </H5>
                </Link>

                <ServicesDropdown
                  services={services}
                  hovered={servicesHovered}
                  toggleHover={toggleServicesHover}
                />
              </li>
            );
          }

          return (
            <NavItem
              key={navKey}
              navKey={navKey}
              name={name}
              toggleNav={toggleNav}
              isLinkActive={isLinkActive}
              hovered={aiHovered}
              toggleHover={toggleAiHover}
              language={language}
            />
          );
        })}
      </ul>

      <div className="get-in-touch-button-mobile">
        <Link
          to={isEnglish(language) ? '/contact' : '/ua/contact'}
          className="nav__link "
        >
          <Button $secondary $width="328px" $onClick={toggleNav}>
            {t('inTouchBtn')}
          </Button>
        </Link>
      </div>
    </nav>
  );
}

function NavItem({
  navKey,
  name,
  toggleNav,
  isLinkActive,
  hovered,
  toggleHover,
  language,
}) {
  const isAI = navKey === 'AI';
  let Image = AiImage;
  if (isLinkActive || hovered) {
    Image = AiImageHovered;
  }

  return (
    <li>
      <Link
        to={getLink(navKey, language)}
        className="nav__link"
        data-page={navKey}
        onClick={toggleNav}
      >
        <H5
          $color="#F3F8FA"
          $hoverColor="#50C5EA"
          $isActive={isLinkActive}
          onMouseEnter={() => isAI && toggleHover(true)}
          onMouseLeave={() => isAI && toggleHover(false)}
        >
          {isAI && <Image className="ai-icon" />}
          {name}
        </H5>
      </Link>
    </li>
  );
}

function ServicesDropdown({ services, hovered, toggleHover }) {
  return (
    <div className={cn('dropdown__wrapper', { visible: hovered })}>
      <ul className="list-unstyled dropdown__nav">
        {services.map((service) => (
          <li key={service.id}>
            <Link
              to={`/services/${service.slug}`}
              className="dropdown__link"
              onClick={() => toggleHover(false)}
            >
              <InputText $hoverColor="#50c5ea">{service.label}</InputText>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

function getLink(name, language) {
  if (!isEnglish(language)) {
    let link;
    switch (name) {
      case 'Home':
        link = '/ua';
        break;
      case 'Our team':
        link = '#our-team-section-view';
        break;
      case 'Case studies':
        link = '#case-studies-section-view';
        break;
      case 'Clients':
        link = '#clients-section-view';
        break;
      default:
        link = `/${name.toLocaleLowerCase()}`;
        break;
    }
    return link;
  }

  let link;
  switch (name) {
    case 'Home':
      link = '/';
      break;
    case 'Our team':
      link = '/our-team';
      break;
    case 'Case studies':
      link = '/case-studies';
      break;
    default:
      link = `/${name.toLocaleLowerCase()}`;
      break;
  }
  return link;
}

function checkLink(pathname, name) {
  const isHomeLinkActive =
    name === 'Home' && (pathname === '/' || pathname === '/ua');
  const isTeamLinkActive = name === 'Our team' && pathname === '/our-team';
  const isCaseLinkActive = name === 'Case studies' && pathname === '/case-studies';

  return (
    pathname.includes(name?.toLocaleLowerCase()) ||
    isHomeLinkActive ||
    isTeamLinkActive ||
    isCaseLinkActive
  );
}
