import Circle from '../figures/Circle';
import H2 from '../typography/H2';
import Paragraph from '../typography/Paragraph';
import SecondaryText from '../typography/SecondaryText';

export default function PromoInfo({
  startTitle,
  secondaryTitle,
  endTitle,
  description,
  isActive = 0,
}) {
  return (
    <div className="container promo__info-title">
      <div className="promo__info-title__body">
        <div className="promo__info-title__text">
          <H2 $align="left">
            {startTitle} <SecondaryText>{secondaryTitle}</SecondaryText> {endTitle}
          </H2>
          <Paragraph $maxWidth="488px" $align="left">
            {description}
          </Paragraph>
        </div>

        <div id="promo__info-rect">
          <Circle styles={{ $position: 'relative', $active: isActive ? 1 : 0 }} />
        </div>
      </div>
    </div>
  );
}
