import React from 'react';
import styled from 'styled-components';

const H1Styled = styled.div`
  text-align: ${(props) => props.$align};

  /* New/H1 */
  font-family: 'Inter-ExtraBold', sans-serif;
  font-size: 88px;
  font-weight: 800;
  line-height: 106px;
  letter-spacing: 1.76px;
  text-transform: uppercase;
  color: ${(props) => props.$color || '#F3F8FA'};

  @media (max-width: 1350px) {
    font-size: 77px;
    line-height: 77px;
  }

  @media (max-width: 1210px) {
    font-size: 70px;
    line-height: 70px;
  }

  @media (max-width: 767px) {
    font-size: 40px;
    line-height: 48px;
  }
`;

export default function H1(props) {
  return <H1Styled {...props}>{props.children}</H1Styled>;
}
