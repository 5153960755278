import { useTranslation } from 'react-i18next';
import Promo from '../../../components/commons/Promo';
import Info from './Info';

export default function Help() {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <section className="container help-block">
      <Info />

      {language === 'en' && (
        <Promo
          startText="WANT to KNOW WHY WE’RE "
          secondaryText="THE BEST"
          endText=" AT WHAT WE DO ?"
          isActive={1}
        />
      )}
    </section>
  );
}
