import { useState } from 'react';
import classNames from 'classnames';

import Button from '../buttons/Button';
import ModalView from '../Modal/ModalView';
import ResultModal from '../Modal/ResultModal';
import GetInfoForm from '../forms/GetInfoForm';
import Circle from '../figures/Circle';

import H2 from '../typography/H2';
import H3 from '../typography/H3';
import TextLarge from '../typography/TextLarge';
import SecondaryText from '../typography/SecondaryText';

import { isChristmasToday } from '../../utils/helpers';
import { CHRISTMAS_PINK_COLOR } from '../../utils/constants';

let secondaryColor = null;
if (isChristmasToday()) {
  secondaryColor = CHRISTMAS_PINK_COLOR;
}

const Promo = ({ startText, endText, secondaryText, isActive = 0 }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen((currentState) => !currentState);

  return (
    <section className="promo-section">
      <div className="promo-section-container">
        <H2 $maxWidth="640px" $align="left">
          {startText}{' '}
          <SecondaryText $color={secondaryColor}>{secondaryText}</SecondaryText>{' '}
          {endText}
        </H2>

        <Button $width="312px" $height="50px" $onClick={handleOpenModal}>
          Yes, i want to know
        </Button>
      </div>

      <div className="promo-section-rect">
        <Circle styles={{ $position: 'relative', $active: isActive }} />
      </div>

      <PromoModal isModalOpen={isModalOpen} handleOpenModal={handleOpenModal} />
    </section>
  );
};

export default Promo;

function PromoModal({ isModalOpen, handleOpenModal }) {
  const [isResultModalOpen, setIsResultModalOpen] = useState(false);

  const handleModalButton = () => {
    setIsResultModalOpen(true);
    handleOpenModal();
  };

  return (
    <>
      <ModalView open={isModalOpen} changeParentState={handleOpenModal}>
        <div className="promo-section-modal">
          <H3 className="promo-section-modal__title">Let’s get you to know us!</H3>

          <TextLarge $align="left" className="content-form__title-mobile">
            Please fill out the form, and our team will send you all information.
          </TextLarge>

          <PromoImage isMobile={true} />

          <div className="promo-section-modal__content">
            <div className="promo-section-modal__content-form">
              <GetInfoForm setIsModalOpen={handleModalButton} />
            </div>

            <PromoImage isMobile={false} />
          </div>
        </div>
      </ModalView>

      <ResultModal
        open={isResultModalOpen}
        title="Success"
        description="Presentation should be downloaded shortly"
      />
    </>
  );
}

function PromoImage({ isMobile }) {
  const wrapperClass = classNames({
    'promo-section-modal__content-image': !isMobile,
    'promo-section-modal__content-image-mobile': isMobile,
  });
  const imageClass = classNames({
    'promo-pdf-preview': !isMobile,
    'promo-pdf-preview-mobile': isMobile,
  });
  return (
    <div className={wrapperClass}>
      <img
        src="/img/promo/bookcover.png"
        alt="Deviark presentation"
        className={imageClass}
      />
    </div>
  );
}
