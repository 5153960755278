import H4Secondary from '../../../../components/typography/H4Secondary';
import TextLargeSecondary from '../../../../components/typography/TextLargeSecondary';

export default function TabContent({ tab }) {
  return (
    <>
      <H4Secondary $useMedia $color="#E1F1FF">
        {tab.title}
      </H4Secondary>{' '}
      <TextLargeSecondary>{tab.text}</TextLargeSecondary>
    </>
  );
}
