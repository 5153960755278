import { useTranslation } from 'react-i18next';

import ItemCard from '../../components/cards/ItemCard';
import Button from '../../components/buttons/Button';
import H4 from '../../components/typography/H4';
import ContactInfo from './ContactInfo';
import { isEnglish } from '../../utils/helpers';

import AndriiPhoto from '../../assets/contact/andrii-photo.png';

export default function ContactCard({ localTime }) {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const handleButtonClick = () => {
    window.open('https://calendly.com/akruten/30min?month=2023-10', '_blank');
  };

  return (
    <ItemCard
      $padding="40px"
      $flexDirection="column"
      $gap="unset"
      className="andrii-card"
    >
      <img src={AndriiPhoto} alt="Andrii Kruten" className="andrii-photo" />

      <H4 className="contact-title">{t('contactPageCardTitle')}</H4>
      {isEnglish(language) && <H4>CEO</H4>}
      {!isEnglish(language) && <H4>Засновник</H4>}

      <ContactInfo localTime={localTime} />

      <Button $width="312px" $height="51px" $onClick={handleButtonClick}>
        <a
          href="https://calendly.com/akruten/30min?month=2023-10"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('contactPageCardBookCall')}
        </a>
      </Button>
    </ItemCard>
  );
}
