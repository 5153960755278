import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { getReviews } from '../../../services/reviewsList';
import ReviewItem from './ReviewItem';
import ArrowButton from '../../../components/buttons/ArrowButton';

import { register } from 'swiper/element/bundle';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

register();

const viewPresets = {
  768: {
    slidesPerView: 1.1,
  },
  860: {
    slidesPerView: 1.2,
  },
  1000: {
    slidesPerView: 1.3,
  },
  1150: {
    slidesPerView: 1.6,
  },
  1441: {
    slidesPerView: 1,
  },
};

export default function ClientsReviews() {
  const {
    i18n: { language },
  } = useTranslation();

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const reviews = getReviews(language);

  return (
    <div className="clients-reviews">
      <div className="clients-reviews-container">
        <Swiper
          speed={600}
          centeredSlides={true}
          centeredSlidesBounds={true}
          slidesPerView={1}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          breakpoints={viewPresets}
          spaceBetween={30}
          loop
        >
          {reviews.map((review) => {
            return (
              <SwiperSlide key={review.id}>
                <ReviewItem review={review} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>

      <div className="swiper-controls">
        <ArrowButton direction="left" ref={navigationPrevRef} />
        <ArrowButton direction="right" ref={navigationNextRef} />
      </div>
    </div>
  );
}
