import cn from 'classnames';
import Circle from '../../../../components/figures/Circle';
import H4 from '../../../../components/typography/H4';

export default function TabName({ tab, activeTab, handleTabClick }) {
  const isTabActive = activeTab === tab.id;
  return (
    <div
      className={cn('tab', {
        active: isTabActive,
      })}
      onClick={() => handleTabClick(tab.id)}
    >
      {isTabActive ? (
        <Circle styles={{ $position: 'relative', $active: 1 }} />
      ) : (
        <Circle styles={{ $position: 'relative' }} />
      )}

      <H4 $useMedia>{tab.name}</H4>
    </div>
  );
}
