import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';

import ServicesDirections from './ServicesDirections';
import Button from '../../../components/buttons/Button';
import ChristmasTree from '../../../assets/christmas-tree.png';
import { isChristmasToday } from '../../../utils/helpers';

import H1 from '../../../components/typography/H1';
import H4 from '../../../components/typography/H4';
import H5 from '../../../components/typography/H5';
import Paragraph from '../../../components/typography/Paragraph';
import { isEnglish } from '../../../utils/helpers';

export default function Main() {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <section className="main" id="mainBlock">
      <div className="main__background main__background__scene" id="scene">
        <div
          className="main__background main__background__img scaled"
          id="mainBgImage"
          data-depth="0.4"
        >
          {isChristmasToday() && (
            <div className="christmas-tree">
              <img
                src={ChristmasTree}
                alt="Christmas Tree"
                className="christmas-tree__img"
              />
              <H4 className="christmas-tree__title"></H4>
            </div>
          )}
        </div>
      </div>

      <div className="container">
        <div className="main-info">
          <H5 $align="left" className="pretitle">
            {t('homePageTitleSmall')}
          </H5>

          {isEnglish(language) && (
            <H1 $align="left" className="main-title">
              trusted software development{' '}
              <span className="word-T">
                par
                <span className="letter-T">
                  <span className="hidden-T">T</span>
                </span>
                ner
              </span>
            </H1>
          )}

          {!isEnglish(language) && (
            <H1 $align="left" className="main-title">
              {t('homePageTitleLarge')}
            </H1>
          )}

          <Paragraph $align="left" $zIndex="100" className="aftertitle">
            {t('homePageTitleDescription')}
          </Paragraph>

          <div className="get-in-touch-button-main">
            <Link
              to={isEnglish(language) ? '/contact' : '/ua/contact'}
              className="nav__link "
            >
              <Button $secondary $width="324px" $margin="0 auto">
                {t('inTouchBtn')}
              </Button>
            </Link>
          </div>
        </div>
      </div>

      <ServicesDirections />
    </section>
  );
}
