import MainInfo from './MainInfo/MainInfo';
import Industries from './Industries/Industries';
import Technology from './Technology/Technology';
import Help from './Help/Help';
import Innovations from './Innovations/Innovations';
import CaseStudies from './CaseStudies/CaseStudies';
import Clients from './Clients/Clients';
import OurTeamInfo from '../../components/OurTeam/OurTeamInfo';

const Home = ({ services }) => {
  return (
    <>
      <MainInfo services={services} />
      <Technology />
      <Help />
      <Innovations />
      <CaseStudies />
      <Industries />
      <OurTeamInfo />
      <Clients />
    </>
  );
};

export default Home;
