import classNames from 'classnames';
import HeaderBackground from '../../../components/commons/HeaderBackground';
import Promo from '../../../components/commons/Promo';
import TechnologyItem from '../../../components/TechnologyItem/TechnologyItem';
import ItemCard from '../../../components/cards/ItemCard';
import Circle from '../../../components/figures/Circle';

import ButtonText from '../../../components/typography/ButtonText';
import H1 from '../../../components/typography/H1';
import H2 from '../../../components/typography/H2';
import Paragraph from '../../../components/typography/Paragraph';

export default function AiItem({ project }) {
  const preparedTitle = prepareTitle(project.title);

  return (
    <section className="section-ai-item">
      <HeaderBackground />

      <div className="section-ai-item__title">
        <div className="container">{preparedTitle}</div>
      </div>

      <div className="container">
        <div className="section-ai-item__description">
          <Paragraph>{project.description}</Paragraph>
        </div>
      </div>

      <Preview project={project} />

      <Technology project={project} />

      <Discovery discovery={project.discovery} />

      <Solution goals={project.solution} />

      <Results solution={project.results} />

      <PromoComponent />
    </section>
  );
}

function Preview({ project }) {
  return (
    <div className="container section-ai-item__preview">
      <ItemCard $height="100%" className="section-ai-item__preview-card">
        <img
          src={`/img/ai/${project.preview}`}
          alt={project.title}
          className="section-ai-item__preview-image"
        />
      </ItemCard>
    </div>
  );
}

function Technology({ project }) {
  return (
    <div className="container section-ai-item__technology">
      <div className="platfom">
        <ButtonText $align="left" $color="#F3F8FA">
          Platform
        </ButtonText>

        {project.platform.map((p) => {
          return (
            <TechnologyItem name={p?.toUpperCase()} key={p}>
              {p}
            </TechnologyItem>
          );
        })}
      </div>

      <div className="industry">
        <ButtonText $align="left" $color="#F3F8FA">
          Industry
        </ButtonText>

        {project.industry.map((p) => {
          return (
            <TechnologyItem name={p?.toUpperCase()} key={p}>
              {p}
            </TechnologyItem>
          );
        })}
      </div>

      <div className="duration">
        <ButtonText $align="left" $color="#F3F8FA">
          Duration
        </ButtonText>

        <TechnologyItem name="DURATION">{project.duration}</TechnologyItem>
      </div>
    </div>
  );
}

function Discovery({ discovery }) {
  return (
    <div className="container section-ai-item__content">
      <div className="section-ai-item__content-description">
        <H2 $color="#50C5EA" $align="left">
          Discovery
        </H2>
        <Paragraph $align="left">{discovery}</Paragraph>
      </div>
      <div className="section-ai-item__content-circle">
        <Circle styles={{ $position: 'relative', $active: 1 }} />
      </div>
    </div>
  );
}

function Results({ solution }) {
  return (
    <div className="container section-ai-item__content">
      <div className="section-ai-item__content-description">
        <H2 $color="#50C5EA" $align="left">
          Results
        </H2>
        <Paragraph $align="left">{solution}</Paragraph>
      </div>
      <div className="section-ai-item__content-circle">
        <Circle styles={{ $position: 'relative', $active: 1 }} />
      </div>
    </div>
  );
}

function Solution({ goals }) {
  return (
    <div className="container section-ai-item__content reverse">
      <div className="section-ai-item__content-description">
        <H2 $color="#50C5EA" $align="left">
          Solution
        </H2>
        <Paragraph $align="left">{goals}</Paragraph>
      </div>
      <div className="section-ai-item__content-circle">
        <Circle styles={{ $position: 'relative', $active: 1 }} />
      </div>
    </div>
  );
}

function PromoComponent() {
  return (
    <div className="container section-ai-item__promo">
      <Promo
        startText={
          <>
            Ready to talk? <br />
          </>
        }
        secondaryText="Let’s build"
        endText="something great together"
        isActive={1}
      />
    </div>
  );
}

function prepareTitle(title) {
  const loweredTitle = title.toLowerCase();
  let preparedTitle = '';

  if (loweredTitle === 'ippt+') {
    preparedTitle = highliteLetter('ipp', 't', '+');
  }
  if (loweredTitle === 'homework ai') {
    preparedTitle = highliteLetter('hom', 'e', 'work ai');
  }
  if (loweredTitle === 'ai parenting coach') {
    preparedTitle = highliteLetter('ai par', 'e', 'nting coach', true);
  }
  if (loweredTitle === 'hapday: ai life coach') {
    preparedTitle = highliteLetter('hapday: ai ', 'l', 'ife coach', true);
  }
  if (loweredTitle === 'plum') {
    preparedTitle = highliteLetter('p', 'l', 'um');
  }
  if (loweredTitle === 'lelascan') {
    preparedTitle = highliteLetter('le', 'l', 'ascan');
  }
  if (loweredTitle === 'help ai: chat & ask experts') {
    preparedTitle = loweredTitle;
  }
  if (loweredTitle === 'brainybot') {
    preparedTitle = highliteLetter('brainybo', 't', '');
  }

  return <H1>{preparedTitle}</H1>;
}

function highliteLetter(startPart, letter, endPart, noLineForSmallScreen = false) {
  let letterPart = 't-part';
  if (letter === 'l') {
    letterPart = 'l-part';
  }
  if (letter === 'e') {
    letterPart = 'e-part';
  }

  return (
    <>
      {startPart.toUpperCase()}
      <span
        className={classNames(`ai-title-letter ${letterPart}`, {
          'no-line-small-screen': noLineForSmallScreen,
        })}
      >
        {letter.toUpperCase()}
      </span>
      {endPart.toUpperCase()}
    </>
  );
}
