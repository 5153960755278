import React from "react";
import styled from "styled-components";

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: ${(props) => props.$height || "450px"};
  width: ${(props) => props.$width || "362px"};
  flex: 1 0 0;
  background: rgba(255, 255, 255, 0.05);
`;

export default function Card(props) {
  return <StyledCard {...props}>{props.children}</StyledCard>;
}
