import { useState } from 'react';
import { useParams } from 'react-router-dom';

import ApplyForm from './ApplyForm';
import ResultModal from '../../components/Modal/ResultModal';
import HeaderBackground from '../../components/commons/HeaderBackground';
import NotFoundRedirect from '../../utils/notFoundRedirect';
import Circle from '../../components/figures/Circle';

import { getJobs } from '../../services/jobsList';
import Meta from '../../utils/appendMeta';

import H1 from '../../components/typography/H1';
import H2 from '../../components/typography/H2';
import Paragraph from '../../components/typography/Paragraph';

const Job = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { slug } = useParams();
  const jobsList = getJobs();
  const job = jobsList.find((j) => j.slug === slug);

  if (!job) return <NotFoundRedirect />;

  const { meta } = job;
  const metaData = {
    title: meta.title + ' at Deviark',
    description: 'Join our team as ' + meta.description,
  };
  return (
    <>
      <Meta metaData={metaData} />

      <section className="job-page-content">
        <HeaderBackground />

        <SectionPart>
          <H1 className="job-page-title">{job.title}</H1>

          <div className="careers__job-description">
            <JobDescription job={job} />

            <JobOffer offers={job.offers} />
          </div>
        </SectionPart>

        <div className="job-page-divider"></div>

        <div className="container">
          <div className="careers__apply">
            <H2 $align="left" className="heading">
              Ready to Join?
            </H2>

            <ApplyForm jobTitle={job.title} setIsModalOpen={setIsModalOpen} />
          </div>
        </div>
      </section>

      <ResultModal
        open={isModalOpen}
        title="Application submitted!"
        description="Thank you for considering Deviark as your potential workplace. We'll be in touch as soon as possible to provide updates on the status of your application."
      >
        Text
      </ResultModal>
    </>
  );
};

export default Job;

function SectionPart({ children }) {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-10 col-lg-12">{children}</div>
      </div>
    </div>
  );
}

function JobDescription({ job }) {
  return (
    <div className="job-base-description">
      <div className="job-description">
        <Description requirements={job.requirements} optional={job.optional} />
      </div>

      <div className="description-circle">
        <Circle styles={{ $position: 'relative', $active: 1 }} />
      </div>
    </div>
  );
}

function JobOffer({ offers }) {
  return (
    <div className="job-offer">
      <div className="offer">
        <Offers offers={offers} />
      </div>

      <div className="offer-circle">
        <Circle styles={{ $position: 'relative', $active: 1 }} />
      </div>
    </div>
  );
}

function Description({ requirements, optional }) {
  return (
    <>
      <div className="job-requirements">
        <H2 className="job-description-title">Requirements:</H2>

        <ul>
          {requirements.map((requirement) => {
            return (
              <li key={requirement}>
                <Paragraph>{requirement}</Paragraph>
              </li>
            );
          })}
        </ul>
      </div>

      <div className="job-optional">
        <H2 className="job-description-title">Optional:</H2>

        <ul>
          {optional.map((option) => {
            return (
              <li key={option}>
                <Paragraph>{option}</Paragraph>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}

function Offers({ offers }) {
  return (
    <>
      <H2 className="job-description-title">What we offer:</H2>

      <ul>
        {offers.map((offer) => {
          return (
            <li key={offer}>
              <Paragraph>{offer}</Paragraph>
            </li>
          );
        })}
      </ul>
    </>
  );
}
