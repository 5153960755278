import Meta from '../../utils/appendMeta';
import HeaderBackground from '../../components/commons/HeaderBackground';

import Circle from '../../components/figures/Circle';

import H1 from '../../components/typography/H1';
import H2 from '../../components/typography/H2';
import Paragraph from '../../components/typography/Paragraph';

const metaData = {
  title: 'Privacy Policy | Deviark',
  description: 'Privacy Policy of Deviark LLC',
};

const Gdpr = () => {
  const currentDate = new Date().toLocaleDateString();

  return (
    <div>
      <Meta metaData={metaData} />

      <section className="gdpr-section document-part">
        <HeaderBackground />

        <div className="container">
          <div className="section__head">
            <H1>Privacy Policy of Deviark LLC</H1>
          </div>

          <div>
            <div className="text-section">
              <Paragraph>
                <p>
                  Deviark LLC is aware of the trust that you are putting in us. For
                  this reason, we would like to comprehensively inform you about our
                  approach to handling your personal data. In particular, we would
                  like to inform you about what data is collected during your visit
                  and / or the use of our web site and our online services, and what
                  use they are subject to in detail.
                </p>
                <p>
                  Insofar as we have received personal data from you by other way
                  (e.g. by e-mail or unsolicited application), the following data
                  protection declaration also applies. We use various techniques on
                  our website that are used to improve usability, effectiveness and
                  safety. In this context, data collection may occur by us or third
                  parties (technical service providers) employed and commissioned by
                  us.
                </p>
                <p>Last updated: {currentDate}</p>
              </Paragraph>
            </div>

            <div className="text-section">
              <div className="text-section__title-first">
                <H2 $align="left" className="gdpr-title">
                  <span className="gdpr-highlighted">1.</span> Introduction
                </H2>
                <div className="circle-gdpr">
                  <Circle styles={{ $position: 'relative', $active: 1 }} />
                </div>
              </div>

              <Paragraph>
                <p>
                  Welcome to{' '}
                  <a href="https://deviark.com" className="gdpr-link">
                    https://deviark.com
                  </a>
                  . This privacy policy explains how we use the personal data we
                  collect from you when you use our website, including our contact
                  form, more information form and job seeker/career form, and our use
                  of cookies.
                </p>
              </Paragraph>
            </div>

            <div className="text-section">
              <H2 $align="left" className="gdpr-title">
                <span className="gdpr-highlighted">2.</span> Data We Collect
              </H2>

              <Paragraph>
                <p>We collect the following data:</p>
                <ul className="list-letters">
                  <li>
                    Personal identification information (Full Name, Email Address,
                    Phone number, Location, LinkedIn profile link) provided through
                    forms on our website (contact form, information form, job
                    seeker/career form)
                  </li>
                  <li>
                    Information on how you use our website through cookies and
                    similar technologies.
                  </li>
                </ul>
              </Paragraph>
            </div>

            <div className="text-section">
              <H2 $align="left" className="gdpr-title">
                <span className="gdpr-highlighted">3.</span> How We Collect Your Data
              </H2>

              <Paragraph>
                <p>We collect data when you:</p>
                <ul className="list-letters">
                  <li>
                    Fill in our contact form, information form or job seeker/career
                    form.
                  </li>
                  <li>Use or view our website via your browser's cookies.</li>
                </ul>
              </Paragraph>
            </div>

            <div className="text-section">
              <H2 $align="left" className="gdpr-title">
                <span className="gdpr-highlighted">4.</span> How We Use Your Data
              </H2>

              <Paragraph>
                <p>We use your data to:</p>
                <ul className="list-letters">
                  <li>Process your inquiry, made through our contact form.</li>
                  <li>Process your inquiry, made through the information form.</li>
                  <li>
                    Process your application, made through our job seeker/career
                    form.
                  </li>
                  <li>
                    Understand how you use our website and improve its functionality.
                  </li>
                  <li>
                    Send you promotional emails about products, services, offers, and
                    other things we think might be relevant to you, if you agree.
                  </li>
                </ul>
              </Paragraph>
            </div>

            <div className="text-section">
              <H2 $align="left" className="gdpr-title">
                <span className="gdpr-highlighted">5.</span> Cookies and How We Use
                Them
              </H2>

              <Paragraph>
                <p>
                  Cookies are small text files placed on your device when you visit
                  our website. We use cookies to:
                </p>
                <ul className="list-letters">
                  <li>Understand how you use our site and improve its usability.</li>
                  <li>
                    Customize our website according to your online behavior and
                    personal preferences.
                  </li>
                </ul>
              </Paragraph>
            </div>

            <div className="text-section">
              <H2 $align="left" className="gdpr-title">
                <span className="gdpr-highlighted">6.</span> Managing Cookies
              </H2>

              <Paragraph>
                You can set your browser not to accept cookies, and our website will
                tell you how to remove cookies from your browser. However, in a few
                cases, some of our website features may not function as a result.
              </Paragraph>
            </div>

            <div className="text-section">
              <H2 $align="left" className="gdpr-title">
                <span className="gdpr-highlighted">7.</span> Storing and Protecting
                Your Data
              </H2>

              <Paragraph>
                Deviark LLC stores your data on a secured web server managed by
                Deviark LLC or an agent of Deviark LLC performing services on our
                behalf. We retain your personal information for as long as necessary
                to provide the services you have requested, and thereafter for a
                variety of legitimate legal or business purposes.
              </Paragraph>
            </div>

            <div className="text-section">
              <H2 $align="left" className="gdpr-title">
                <span className="gdpr-highlighted">8.</span> Your Data Protection
                Rights
              </H2>

              <Paragraph>
                You have the right to access, correct, or delete the personal
                information we hold about you. You also have the right to restrict or
                object to our processing of your personal information and the right
                to data portability.
              </Paragraph>
            </div>

            <div className="text-section">
              <H2 $align="left" className="gdpr-title">
                <span className="gdpr-highlighted">9.</span> Privacy Policies of
                Other Websites
              </H2>

              <Paragraph>
                Our website may contain links to other websites. Our privacy policy
                applies only to our website, so if you click on a link to another
                website, you should read their privacy policy.
              </Paragraph>
            </div>

            <div className="text-section">
              <H2 $align="left" className="gdpr-title">
                <span className="gdpr-highlighted">10.</span> Changes to Our Privacy
                Policy
              </H2>

              <Paragraph>
                We review our privacy policy regularly and place any updates on this
                web page.
              </Paragraph>
            </div>

            <div className="text-section">
              <H2 $align="left" className="gdpr-title">
                <span className="gdpr-highlighted">11.</span> How to Contact Us
              </H2>

              <Paragraph>
                For any questions about our privacy policy or the data we hold on
                you, or to exercise your data protection rights, please do not
                hesitate to contact us at{' '}
                <a href="mailto:privacy@deviark.com" className="gdpr-link">
                  privacy@deviark.com
                </a>
                .
              </Paragraph>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Gdpr;
