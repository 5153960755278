import React from 'react';
import styled, { css } from 'styled-components';

const ItemCardStyled = styled.div`
  padding: ${(props) => props.$padding || '10px 20px'};
  margin: ${(props) => props.$margin};
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};

  display: flex;
  flex-direction: ${(props) => props.$flexDirection || 'row'};
  justify-content: ${(props) => props.$justifyContent || 'center'};
  align-items: ${(props) => props.$alignItems || 'center'};
  gap: ${(props) => props.$gap || '16px'};

  border-radius: 1px;
  border: ${(props) => props.$border || '1px solid'};
  border-image: linear-gradient(to right top, #011730, #50c5ea, #011730);
  border-image-slice: 1;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);

  ${(props) =>
    props.$useBorderAnimation &&
    css`
      &:hover {
        border-image: linear-gradient(to right top, #011730, #011730, #011730);
      }
    `}
`;

export default function ItemCard(props) {
  return <ItemCardStyled {...props}>{props.children}</ItemCardStyled>;
}
