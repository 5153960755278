const aiProjects = [
  {
    id: 1,
    slug: 'ippt',
    title: 'Ippt+',
    tags: ['Mobile development', 'Design'],
    imgSrc: 'ippt-ai.svg',
    preview: 'ippt-preview.gif',
    description:
      'A newly designed IPPT+ app aims to be your friendly companion to help you reach your results (using power of ChatGPT)',
    platform: ['iOS', 'Android'],
    industry: ['Fitness'],
    duration: '6 months - 2 app versions',
    discovery:
      'A client from Singapore contacted us about his current Android and iOS apps, which were made for the fitness industry. His idea was to integrate AI into the “Training Plan” section, in order to allow users to form plans based on their input.',
    solution:
      'ChatGPT was an obvious solution for this task and after some evaluation of the pricing and terms we have suggested our client use the GPT-4 version.',
    results:
      'AI has significantly enhanced the app`s functionality and there’s been a massive boost in active users since then.',
  },
  {
    id: 2,
    slug: 'homework',
    title: 'HOMEWORK AI',
    tags: ['Mobile development', 'Design'],
    imgSrc: 'homework-ai.svg',
    preview: 'homework-preview.gif',
    description:
      'HomeWork AI is the ultimate study buddy. Harness the power of AI to get help with math equations, written questions, and full-blown essay writing.',
    platform: ['iOS'],
    industry: ['Education'],
    duration: '6 months',
    discovery:
      'A client came to us with the idea of an AI homework app that could help students with their homework and assignments. An easy-to-use tool that could provide quick results using only a camera.',
    solution:
      'We have suggested using Vision Framework for text detection and GPT-4 for answer generation.',
    results:
      'The app was successfully launched onto the App Store and has gained many users since then.',
  },
  {
    id: 3,
    slug: 'parenting-coach',
    title: 'AI PARENTING COACH',
    tags: ['Mobile development', 'Design'],
    imgSrc: 'coach-ai.svg',
    preview: 'coach-preview.gif',
    description:
      'AI Parenting Coach is a groundbreaking parenting advice app that harnesses the power of artificial intelligence to provide unparalleled support and guidance to parents.',
    platform: ['iOS'],
    industry: ['Education'],
    duration: '6 months',
    discovery:
      'We have received a request to develop a parenting advise app, utilizing AI capabilities. The final product was supposed to have a predefined set of topics and questions that users could choose, with an ability to customize a request too.',
    solution:
      'GPT-4 was suggested for the AI-generated answers. The client has also provided us with a list of topics that should be included in the app. AI bot was trained to provide specific answers which were added to the app as well.',
    results:
      'A GPT-4 powered iOS application, that is now being used by parents worldwide.',
  },
  {
    id: 5,
    slug: 'plum',
    title: 'PLUM',
    tags: ['Mobile development', 'Design'],
    imgSrc: 'plum-ai.svg',
    preview: 'plum-preview.gif',
    description:
      'An innovative iOS application which helps people to maintain healthy activities and track food consumption.',
    platform: ['iOS'],
    industry: ['Health Care'],
    duration: '1 year',
    discovery:
      'Our team was approached by a University of Otago from New Zealand and they have asked us to develop an iOS application that would allow tracking of food consumption and improving the dietary assessment for diabetes and obesity. In order to do this we had to implement an image recognition library into the application (https://www.passio.ai/), so that users could “scan” the food via camera and see nutrition data.',
    solution:
      'To understand what people are eating (food and drink) and use that data for scientific studies (to fight problems with obesity in the region).',
    results:
      'An iOS application with Firebase backend and Passio.ai integration (for food recognition) The application was successfully launched and is now being used for field testing.',
  },
  {
    id: 6,
    slug: 'lelascan',
    title: 'LELASCAN',
    tags: ['Mobile development', 'Design'],
    imgSrc: 'lelascan-ai.svg',
    preview: 'lelascan-preview.gif',
    description:
      'A document scanning application for a DE market, which is used by freelancers all over the country.',
    platform: ['iOS'],
    industry: ['Finance'],
    duration: '3 months',
    discovery:
      'We were approached by a client from Germany, regarding an innovative scanning application that could be used to fill tax information by freelancers (instead of filling out the forms).',
    solution:
      'The idea was to have a scanning functionality with OCR and data export. And Vision Framework was selected for the text recognition. We were also told that it would be good to connect some AI tools to analyze the data (as we’d need to export only specific fields), so we’ve decided to connect GPT-4 as well.',
    results:
      'An easy-to-use and intuitive application which allows freelancers to scan their receipts and expenses in a few taps on the smartphone and therefore save tons of time.',
  },
  {
    id: 8,
    slug: 'brainybot',
    title: 'BRAINYBOT',
    tags: ['Mobile development', 'Design'],
    imgSrc: 'brainybot-ai.svg',
    preview: 'brainybot-preview.gif',
    description:
      'A powerful tool that can provide users with quick, accurate and personalized responses to their questions, making it an asset to individuals and organizations alike.',
    platform: ['iOS', 'Android'],
    industry: ['Utilities'],
    duration: '3 months',
    discovery:
      'When OpenAI first announced ChatGPT, our company decided that we had to try it out. And not only as an online service but in a real app. Thus the idea of an AI-powered chatbot app emerged.',
    solution:
      'We have created 2 apps (iOS and Android) that were using OpenAI API in order to provide the answer to any question. The apps also allowed users to add voice messages (speech-to-text), and export the results to PDF.',
    results:
      'The apps were promoted on our Linkedin pages and among our friends and partners and were used by a large amount of users for some time. But after a while, we’ve decided to remove them from the sale, as they have already served their purpose (of showcasing the AI capabilities).',
  },
];

export default aiProjects;
