import { useParams } from 'react-router-dom';
import Meta from '../../utils/appendMeta';
import NotFoundRedirect from '../../utils/notFoundRedirect';
import HeaderBackground from '../../components/commons/HeaderBackground';
import TechnologyItem from '../../components/TechnologyItem/TechnologyItem';
import ItemCard from '../../components/cards/ItemCard';
import Promo from '../../components/commons/Promo';
import PromoInfo from '../../components/commons/PromoInfo';
import OtherServices from './OtherServices';

import T_WORD from '../../components/letters/T_WORD';
import I_LETTER from '../../components/letters/I_LETTER';

import H1 from '../../components/typography/H1';
import H3 from '../../components/typography/H3';
import H4 from '../../components/typography/H4';
import Paragraph from '../../components/typography/Paragraph';

const Service = ({ services }) => {
  const { slug } = useParams();
  const service = services.find((s) => s.slug === slug);

  if (!service) return <NotFoundRedirect />;

  const { id, description, overview, details, technologies, meta } = service;
  const metaData = {
    title: meta.title,
    description: meta.description,
  };

  const serviceTitle = getTitle(id);

  return (
    <section className="section-container">
      <Meta metaData={metaData} />

      <HeaderBackground />

      <section className={'service__header service__header--' + id}>
        <div className="container">
          {serviceTitle}
          <Paragraph className="description">{description}</Paragraph>
        </div>
      </section>

      <section className="service__technologies">
        <div className="container">
          {Object.entries(technologies).map(([key, value]) => {
            return (
              <TechnologyItem name={key} key={key}>
                {value}
              </TechnologyItem>
            );
          })}
        </div>
      </section>

      <PromoInfo
        startTitle={overview.heading.startTitle}
        secondaryTitle={overview.heading.secondaryTitle}
        endTitle={overview.heading.endTitle}
        description={overview.description}
        isActive={1}
      />

      <section className="service__details">
        <div className="container">
          <div className="section__head">
            <H3 $align="left">{details.heading}</H3>
          </div>

          <div className={`services__grid ${id}`}>
            {details.list.map((item) => (
              <div className="services__item" key={item.heading}>
                <ItemCard
                  $flexDirection="column"
                  $justifyContent="start"
                  $alignItems="start"
                  $height="100%"
                  className="services__item-card"
                >
                  <H4 $align="left">{item.heading}</H4>
                  <Paragraph>{item.description}</Paragraph>
                </ItemCard>
              </div>
            ))}
          </div>
        </div>
      </section>

      <div className="container">
        <Promo
          startText="Ready to talk? "
          secondaryText="Let’s build"
          endText=" something great together"
          isActive={1}
        />
      </div>

      <div className="container">
        <OtherServices services={services} slug={slug} />
      </div>
    </section>
  );
};

export default Service;

function getTitle(id) {
  switch (id) {
    case 'software':
      return (
        <>
          <H1 className="service-title desktop">
            Enterprise <T_WORD start="Sof" end="ware" /> Development
          </H1>
          <H1 className="service-title mobile">
            Enterprise Software <T_WORD start="Developmen" end="" />
          </H1>
        </>
      );
    case 'management':
      return (
        <>
          <H1 className="service-title desktop">
            <T_WORD start="Projec" end="" /> Management
          </H1>
          <H1 className="service-title mobile">
            Project <T_WORD start="Managemen" end="" />
          </H1>
        </>
      );
    case 'mobile':
      return (
        <H1 className="service-title">
          Mobile <T_WORD start="Developmen" end="" />
        </H1>
      );
    case 'web':
      return (
        <H1 className="service-title">
          Web <T_WORD start="Developmen" end="" />
        </H1>
      );
    case 'consulting':
      return (
        <H1 className="service-title">
          IT <T_WORD start="Consul" end="ing" />
        </H1>
      );
    case 'uiux':
      return (
        <H1 className="service-title">
          Des
          <I_LETTER />
          gn
        </H1>
      );
    case 'staff':
      return (
        <H1 className="service-title">
          IT Staff <T_WORD start="Augmen" end="ation" />
        </H1>
      );
    case 'qa':
      return (
        <H1 className="service-title">
          <T_WORD start="Tes" end="ing" /> and QA
        </H1>
      );
    default:
      return <></>;
  }
}
