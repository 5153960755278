import { Link } from 'react-router-dom';
import ItemCard from '../../components/cards/ItemCard';
import Circle from '../../components/figures/Circle';
import classNames from 'classnames';
import H4 from '../../components/typography/H4';
import { isChristmasToday } from '../../utils/helpers';
import { CHRISTMAS_GREEN_COLOR, CHRISTMAS_PINK_COLOR } from '../../utils/constants';

let circleStyles = { $position: 'relative' };
const isChristmas = isChristmasToday();

export default function Position({ job, i }) {
  if (isChristmas) {
    circleStyles = {
      ...circleStyles,
      $disableBgImage: 'none',
    };

    if ([0, 2, 4].includes(i)) {
      circleStyles['$bgColor'] = CHRISTMAS_PINK_COLOR;
    } else {
      circleStyles['$bgColor'] = CHRISTMAS_GREEN_COLOR;
    }
  }

  return (
    <div className="careers__position-container">
      <div className="carrers__position-rect">
        <Circle styles={circleStyles} />
      </div>

      <div className="careers__position">
        {job.badge && (
          <div
            className={`badge badge--${job.badge}`}
            style={{
              ...(isChristmas &&
                job.badge === 'new' && { background: CHRISTMAS_PINK_COLOR }),
            }}
          >
            <div className="badge__text">{job.badge}</div>
          </div>
        )}

        <Link to={`/careers/${job.slug}`}>
          <ItemCard
            className={classNames('careers__position-card', {
              transformed: !!job.badge,
            })}
          >
            <span className="heading careers__position__title">
              <H4 $align="left">{job.title}</H4>
            </span>
          </ItemCard>
        </Link>
      </div>
    </div>
  );
}
