import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Navigation from './Navigation/Navigation';
import Button from '../../components/buttons/Button';
import MenuButton from './Navigation/MenuButton';

import { isEnglish } from '../../utils/helpers';

const Header = ({ services }) => {
  const [isVisible, setVisibility] = useState(false);
  const toggleNav = () => setVisibility((prev) => !prev);

  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <header className="header d-flex justify-content-between align-items-center">
      <Link to={isEnglish(language) ? '/' : '/ua'}>
        <img
          src="/img/logo-blue.svg"
          alt="Deviark Logo Blue"
          className="logo-icon"
        />
        <img src="/img/logo-title.svg" alt="Deviark Title" className="logo-title" />
      </Link>

      <Navigation services={services} isVisible={isVisible} toggleNav={toggleNav} />

      <div className="get-in-touch-button-desktop">
        <Link
          to={isEnglish(language) ? '/contact' : '/ua/contact'}
          className="nav__link "
        >
          <Button $secondary>{t('inTouchBtn')}</Button>
        </Link>
      </div>

      <MenuButton isVisible={isVisible} toggleNav={toggleNav} />
    </header>
  );
};

export default Header;
