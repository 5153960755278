import { Link } from 'react-router-dom';
import classnames from 'classnames';
import ItemCard from '../../components/cards/ItemCard';
import H3 from '../../components/typography/H3';
import H4 from '../../components/typography/H4';

export default function OtherServices({ services, slug }) {
  return (
    <div className="other-services">
      <H3 className="service-title">Other Services</H3>

      <div className="other-services__list">
        {services.map((service) => {
          return (
            <div
              key={service.slug}
              className={classnames('services-list__item', {
                'd-none': slug === service.slug,
              })}
            >
              <Link to={`/services/${service.slug}`}>
                <ItemCard
                  $border="none"
                  $justifyContent="start"
                  className="services-card"
                >
                  <H4 $align="left" $useMedia>
                    {service.label}
                  </H4>
                </ItemCard>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
}
