import ChristmasTree from '../../assets/christmas-tree.png';
import { isChristmasToday } from '../../utils/helpers';
import H4 from '../../components/typography/H4';

export default function HeaderBackground() {
  return (
    <div className="header__background-page__background header__background-page__background__scene">
      <div
        className="header__background-page__background header__background-page__background__img scaled"
        data-depth="0.4"
      >
        {isChristmasToday() && (
          <div className="christmas-tree">
            <img
              src={ChristmasTree}
              alt="Christmas Tree"
              className="christmas-tree__img"
            />
            <H4 className="christmas-tree__title"></H4>
          </div>
        )}
      </div>
    </div>
  );
}
