import React from 'react';
import { Helmet } from 'react-helmet';

const Meta = ({ metaData: {title, description} }) => {
    return ( 
        <Helmet defaultTitle="Deviark | UI/UX Design, Mobile and Web Development Experts">
            {/* General tags */}
            <title>{ title }</title>
            <meta name="title" content={ title } />
            <meta name="description" content={ description } />
            {/* OpenGraph tags */}
            <meta property="og:title" content={ title } />
            <meta property="og:description" content={ description } />
            {/* Twitter Card tags */}
            <meta name="twitter:title" content={ title } />
            <meta name="twitter:description" content={ description } />
        </Helmet>
    );
}
 
export default Meta;