import React from "react";
import styled from "styled-components";

const SecondaryTextStyled = styled.span`
  color: ${(props) => props.$color || "#50C5EA"};
  text-transform: ${(props) => props.$textTransform};
`;

export default function SecondaryText(props) {
  return <SecondaryTextStyled {...props}>{props.children}</SecondaryTextStyled>;
}
