export default function T_WORD({ start, end }) {
  return (
    <span className="word-T">
      {start}
      <span className="letter-T">
        <span className="hidden-T">T</span>
      </span>
      {end}
    </span>
  );
}
