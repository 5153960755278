import React, { forwardRef } from 'react';
import Button from './Button';
import Left from '../../assets/swiper-buttons/left-arrow.svg';
import Right from '../../assets/swiper-buttons/right-arrow.svg';

const ArrowButton = forwardRef(
  ({ direction, width = '72px', height = '72px', onClick }, ref) => {
    return (
      <Button
        $secondary
        $width={width}
        $height={height}
        $padding="10px 10px"
        $useArrowHover
        $onClick={onClick}
        ref={ref}
      >
        <img src={direction === 'left' ? Left : Right} alt="direction" />
      </Button>
    );
  }
);
export default ArrowButton;
