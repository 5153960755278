const reviewsEn = [
  {
    id: 1,
    rate: '5.0',
    text: 'They deliver what they promise and they exhaust their effort for the best outcome.',
    source: 'https://clutch.co/profile/deviark#review-1556102',
    teamSize: 2,
    cooperationTime: 1,
    author: {
      name: 'Vasilios Papakostas',
      position: 'General Manager',
      company: 'ITeQ SA',
    },
  },
  {
    id: 2,
    rate: '5.0',
    text: 'I was impressed by the smooth communication with everyone from Deviark.',
    source: 'https://clutch.co/profile/deviark#review-1207515',
    teamSize: 8,
    cooperationTime: 1,
    author: {
      position: 'Senior IT Consultant & Project Manager',
      company: 'Alten GmbH',
    },
  },
  {
    id: 3,
    rate: '5.0',
    text: "The client applauded Deviark's high-quality work, fast deliveries, and great team communication skills.",
    source: 'https://clutch.co/profile/deviark#review-1713849',
    teamSize: 3,
    cooperationTime: 5,
    author: {
      position: 'CEO',
      company: 'Top Technologies',
    },
  },
  {
    id: 4,
    rate: '5.0',
    text: 'We are really satisfied, which is why this has been going on for two years now.',
    source: 'https://clutch.co/profile/deviark#review-1705127',
    teamSize: 5,
    cooperationTime: 5,
    author: {
      name: 'Adnan Khan',
      position: 'Founder & CEO',
      company: 'Arcane People Sweden AB',
    },
  },
  {
    id: 5,
    rate: '5.0',
    text: 'They go the extra mile, care deeply about their work, and do what it takes to get everything done.',
    source: 'https://clutch.co/profile/deviark#review-1734990',
    teamSize: 1,
    cooperationTime: 4,
    author: {
      name: 'Britt Myers',
      position: 'Founder & Owner',
      company: 'My Evil Twin',
    },
  },
];

const reviewsUa = [
  {
    id: 1,
    rate: '5.0',
    text: 'Команда робить саме те, що обіцяє, і старається на повну силу для досягнення найкращих результатів.',
    source: 'https://clutch.co/profile/deviark#review-1556102',
    teamSize: 2,
    cooperationTime: 1,
    author: {
      name: 'Vasilios Papakostas',
      position: 'ГЕНЕРАЛЬНИЙ МЕНЕДЖЕР',
      company: 'ITeQ SA',
    },
  },
  {
    id: 2,
    rate: '5.0',
    text: 'Я був вражений приємною комунікацією з кожним членом команди Девіарк.',
    source: 'https://clutch.co/profile/deviark#review-1207515',
    teamSize: 8,
    cooperationTime: 1,
    author: {
      position: 'СТАРШИЙ IT КОНСУЛЬТАНТ ТА МЕНЕДЖЕР',
      company: 'Alten GmbH',
    },
  },
  {
    id: 3,
    rate: '5.0',
    text: 'Нам дуже сподобався рівень роботи, швидке виконання завдань, і також чудова комунікація з командою.',
    source: 'https://clutch.co/profile/deviark#review-1713849',
    teamSize: 3,
    cooperationTime: 5,
    author: {
      position: 'CEO',
      company: 'Top Technologies',
    },
  },
  {
    id: 4,
    rate: '5.0',
    text: 'Ми дуже задоволені, і тому вже понад 2 роки працюємо з Девіарк.',
    source: 'https://clutch.co/profile/deviark#review-1705127',
    teamSize: 5,
    cooperationTime: 5,
    author: {
      name: 'Adnan Khan',
      position: 'Founder & CEO',
      company: 'Arcane People Sweden AB',
    },
  },
  {
    id: 5,
    rate: '5.0',
    text: 'Вони завжди стараються на повну, переживають за фінальний результат, і роблять все від них можливе.',
    source: 'https://clutch.co/profile/deviark#review-1734990',
    teamSize: 1,
    cooperationTime: 4,
    author: {
      name: 'Britt Myers',
      position: 'ВЛАСНИК ТА ЗАСНОВНИК',
      company: 'My Evil Twin',
    },
  },
];

export function getReviews(language) {
  if (language === 'en') {
    return reviewsEn;
  }
  if (language === 'ua') {
    return reviewsUa;
  }
}
