import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import CloseIcon from './close-icon.svg';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 50px 112px;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  @media (max-width: 767px) {
    padding: 25px 56px;
  }

  @media (max-width: 500px) {
    padding: 0;
  }
`;

const ModalContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 674px;
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1px;
  background: #011730;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  text-align: center;
  overflow: hidden;

  @media (max-width: 767px) {
    padding: 16px 16px 24px 16px;
  }

  @media (max-width: 500px) {
    max-height: unset;
  }

  @media (max-height: 600px) {
    overflow: scroll;
  }
`;

const IconImage = styled.img`
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
`;

const ModalView = ({ children, open, changeParentState = () => {} }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const closeModal = () => {
    setIsOpen(false);
    changeParentState();
  };

  return (
    <>
      {isOpen && (
        <ModalOverlay>
          <ModalContent>
            <IconImage src={CloseIcon} alt="Close" onClick={closeModal} />
            {children}
          </ModalContent>
        </ModalOverlay>
      )}
    </>
  );
};

export default ModalView;
