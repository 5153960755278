import FilterItem from './FilterItem';
import { getFilterNames } from '../helpers';

const filterItems = getFilterNames();

export default function FiltersDesktop({ handleFilterCases }) {
  return (
    <div className="container">
      <div className="case-studies-page__filters desktop">
        {filterItems.map((item, i) => {
          return (
            <FilterItem item={item} key={i} handleFilterCases={handleFilterCases} />
          );
        })}
      </div>
    </div>
  );
}
