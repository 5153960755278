import { useTranslation } from 'react-i18next';
import Circle from '../figures/Circle';
import H2 from '../typography/H2';
import TextLarge from '../typography/TextLarge';

export default function OurTeamInfo() {
  const { t } = useTranslation();

  return (
    <section className="container our-team-container" id="our-team-section-view">
      <div className="our-team-rect">
        <Circle
          styles={{ $position: 'relative', $margin: '0 0 0 130px', $active: 1 }}
        />
      </div>

      <div className="our-team-container-info">
        <div className="our-team__text">
          <H2 $align="left" $margin="0 0 16px 0">
            {t('homePageOurTeamTitle')}
          </H2>
          <TextLarge $align="left">{t('homePageOurTeamDescription')}</TextLarge>
        </div>

        <div className="our-team__photo">
          <img
            src="img/our-team/our-team-2.png"
            alt="Our team"
            className="our-team__photo-img"
          />
        </div>
      </div>
    </section>
  );
}
