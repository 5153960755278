import { useState } from 'react';
import ErrorIcon from '../../assets/contact/error-icon.svg';

const Textarea = ({ name, value, label, placeholder, onChange, error, ...rest }) => {
  const hasError = error ? true : false;
  const [showError, setErrorVisibility] = useState(false);
  const handleError = (showError) => setErrorVisibility(showError);

  return (
    <div className="input__group input__group--textarea">
      {showError && (
        <div className="input__error">
          <img src={ErrorIcon} alt="Error" /> {error}
        </div>
      )}

      <textarea
        name={name}
        id={name}
        className="form-input textarea"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        autoComplete="off"
        onFocus={() => handleError(false)}
        onBlur={() => handleError(hasError)}
        {...rest}
      />
      <label htmlFor={name} className="form-input__label">
        {label}
      </label>
    </div>
  );
};

export default Textarea;
