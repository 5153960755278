import { useTranslation } from 'react-i18next';
import TabName from './TabName';
import TabContent from './TabContent';
import ContentImage from './ContentImage';
import { Constants } from '../../constants';

export default function TabsContainerDesktop({ activeTab, handleTabClick }) {
  const {
    i18n: { language },
  } = useTranslation();
  const innovationTabsData =
    Constants[`INNOVATION_TABS_DATA_${language.toUpperCase()}`];

  return (
    <div className="tabs-container">
      <div className="tabs">
        {innovationTabsData?.map((tab) => (
          <TabName
            key={tab.id}
            tab={tab}
            activeTab={activeTab}
            handleTabClick={handleTabClick}
          />
        ))}
      </div>

      <div className="tab-content">
        {innovationTabsData?.map(
          (tab) => activeTab === tab.id && <TabContent key={tab.id} tab={tab} />
        )}

        <ContentImage activeTab={activeTab} />
      </div>
    </div>
  );
}
