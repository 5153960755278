import React from 'react';
import styled, { css } from 'styled-components';

const H4SecondaryStyled = styled.span`
  margin: ${(props) => props.$margin};
  text-align: ${(props) => props.$align || 'center'};

  /* New/H4 */
  font-family: 'Inter-SemiBold', sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.2px;
  text-transform: ${(props) => props.$textTransform || 'uppercase'};
  color: ${(props) => props.$color || '#F3F8FA'};

  /* small screen */
  ${(props) =>
    props.$useMedia &&
    css`
      @media (max-width: 992px) {
        font-size: ${(props) => props.$mediaSmallFontSize || '16px'};
        line-height: ${(props) => props.$mediaSmallLineHeihgt || '20px'};
        letter-spacing: ${(props) =>
          props.$mediaSmallLetterSpacing || '0.16px'};
      }

      @media (max-width: 767px) {
        font-size: ${(props) => props.$mediaSmallFontSize || '16px'};
        line-height: ${(props) => props.$mediaSmallLineHeihgt || '20px'};
        letter-spacing: ${(props) =>
          props.$mediaSmallLetterSpacing || '0.16px'};
      }
    `}
`;

export default function H4Secondary(props) {
  return <H4SecondaryStyled {...props}>{props.children}</H4SecondaryStyled>;
}
