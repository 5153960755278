import Social from '../../components/commons/social';
import InputText from '../../components/typography/InputText';

const ADDRESS = 'Kesklinna linnaosa, Kaupmehe tn 7-120 Tallinn, Estonia';
const ADDRESS_ON_MAP = 'https://maps.app.goo.gl/1jAVE5ikPB13bvFQ7';
const MAIL = 'info@deviark.com';
const PHONE = '+38 (067) 545 84 76';
const PHONE_VALUE = '+380675458476';

export default function ContactInfo() {
  const year = new Date().getFullYear();

  return (
    <>
      <MobileContactInfo />

      <div className="col-12 p-0">
        <hr className="separator" />
      </div>

      <div className="col-12 col-md-12 col-lg-10">
        <ul className="list-unstyled d-flex flex-column flex-wrap align-items-center justify-content-between flex-md-row rules">
          <li>
            <InputText $color="#011730">© Deviark LLC {year}</InputText>
          </li>
          <li className="d-none d-md-block">
            <a href={`mailto:${MAIL}`} className="contact__link">
              <InputText $color="#011730">{MAIL}</InputText>
            </a>
          </li>
          <li className="d-none d-md-block">
            <a href={`tel:${PHONE_VALUE}`} className="contact__link">
              <InputText $color="#011730">{PHONE}</InputText>
            </a>
          </li>
          <li className="d-none d-md-block" style={{ flex: 'auto' }}>
            <a
              href={ADDRESS_ON_MAP}
              target="_blank"
              rel="noopener noreferrer"
              className="contact__link"
            >
              <InputText $color="#011730">{ADDRESS}</InputText>
            </a>
          </li>
        </ul>
      </div>

      <div className="d-none d-md-block col-md-12 col-lg-2">
        <Social classes="justify-content-center justify-content-md-end" />
      </div>
    </>
  );
}

function MobileContactInfo() {
  return (
    <>
      <ul className="d-block d-md-none list-unstyled d-flex flex-column justify-content-start align-items-start">
        <li className="contact__mobile">
          <a href={`mailto:${MAIL}`} className="contact__link">
            <InputText $color="#011730" $align="left">
              {MAIL}
            </InputText>
          </a>
        </li>
        <li className="contact__mobile">
          <a href={`tel:${PHONE_VALUE}`} className="contact__link">
            <InputText $color="#011730" $align="left">
              {PHONE}
            </InputText>
          </a>
        </li>
        <li className="contact__mobile">
          <a
            href={ADDRESS_ON_MAP}
            target="_blank"
            rel="noopener noreferrer"
            className="contact__link"
          >
            <InputText $color="#011730" $align="left">
              {ADDRESS}
            </InputText>
          </a>
        </li>
      </ul>

      <div className="col-12 d-md-none p-0">
        <Social />
      </div>
    </>
  );
}
