import { useTranslation } from 'react-i18next';

import Circle from '../../../components/figures/Circle';
import H3 from '../../../components/typography/H3';
import TextLarge from '../../../components/typography/TextLarge';
import SecondaryText from '../../../components/typography/SecondaryText';
import { isChristmasToday, splitTextForLocalization } from '../../../utils/helpers';
import { CHRISTMAS_PINK_COLOR } from '../../../utils/constants';

let secondaryColor = null;
if (isChristmasToday()) {
  secondaryColor = CHRISTMAS_PINK_COLOR;
}

export default function Info() {
  const { t } = useTranslation();

  const titleText = t('homePageInfoTitle');
  const { leftPart, middlePart, rightPart } = splitTextForLocalization(titleText);

  return (
    <div className="help-block-info">
      <div className="help-rect">
        <Circle styles={{ $position: 'relative' }} />
      </div>

      <div className="help-block-info__text">
        <H3 $align="left">
          {leftPart}
          <SecondaryText $color={secondaryColor}>{middlePart}</SecondaryText>
          {rightPart}
        </H3>

        <TextLarge $align="left">{t('homePageInfoDescription')}</TextLarge>
      </div>
    </div>
  );
}
