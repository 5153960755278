import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useTranslation } from 'react-i18next';

import Meta from './utils/appendMeta';
import Header from './layout/Header/Header';
import Footer from './layout/Footer/Footer';
import Home from './pages/Home/Home';
import Team from './pages/Team/Team';
import AI from './pages/AI/AI';
import AiRedirect from './pages/AI/AiRedirect';
import CaseStudies from './pages/CaseStudies/CaseStudies';
import CaseStudiesRedirect from './pages/CaseStudies/CaseStudiesRedirect';
import Services from './pages/Services/Services';
import Service from './pages/Services/Service';
import Careers from './pages/Careers/Careers';
import Job from './pages/Careers/Job';
import Contact from './pages/Contact/Contact';
import NotFound from './pages/NotFound/NotFound';
import Gdpr from './pages/Gdpr/Gdpr';
import CookiesNotification from './components/cookies/Cookies';

import { getServices } from './services/servicesList';
import './webroot/css/main.css';

const metaData = {
  title: 'Deviark | UI/UX Design, Mobile and Web Development Experts',
  description:
    'We specialize in all kinds of mobile & web development, UI/UX Design, Project Management & QA/QC activities.',
};

function App({ location }) {
  const [services, setServices] = useState([]);
  const {
    i18n: { changeLanguage },
  } = useTranslation();

  function handlePageLanguage() {
    if (location.pathname.includes('ua')) {
      changeLanguage('ua');
    } else {
      changeLanguage('en');
    }
  }

  useEffect(() => {
    setServices(getServices());
  }, []);

  useEffect(() => {
    handlePageLanguage();
    // eslint-disable-next-line
  }, [location.pathname]);

  return (
    <main className="wrapper">
      <Meta metaData={metaData} />
      <Header services={services} />

      <TransitionGroup>
        <CSSTransition key={location.key} classNames="fade" timeout={300}>
          <Switch location={location}>
            <Route
              path="/case-studies/:slug"
              component={(props) => <CaseStudiesRedirect {...props} />}
            />
            <Route path="/case-studies" component={CaseStudies} />
            <Route path="/our-team" component={Team} />
            <Route
              path="/ai/:slug"
              component={(props) => <AiRedirect {...props} />}
            />
            <Route path="/ai" component={AI} />
            <Route
              path="/services/:slug"
              render={(props) => <Service services={services} {...props} />}
            />
            <Route
              path="/services"
              render={(props) => <Services services={services} {...props} />}
            />
            <Route path="/careers/:slug" component={Job} />
            <Route path="/careers" component={Careers} />
            <Route path="/:lang?/contact" component={Contact} />
            <Route path="/privacy-policy" exact component={Gdpr} />
            <Route path="/not-found" exact component={NotFound} />
            <Route
              path="/:lang?/"
              exact
              render={(props) => <Home services={services} {...props} />}
            />
            <Route path="*">
              <Redirect to="/not-found" component={NotFound} />
            </Route>
          </Switch>
        </CSSTransition>
      </TransitionGroup>

      <Footer />
      <CookiesNotification />
    </main>
  );
}

export default withRouter(App);
