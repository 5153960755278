import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ModalView from './ModalView';
import H3 from '../typography/H3';
import TextLarge from '../typography/TextLarge';
import Button from '../buttons/Button';
import SuccessIcon from './success-icon.svg';
import styled from 'styled-components';

const FlexStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 79px;

  @media (max-height: 531px) {
    gap: 50px;
  }

  @media (max-height: 414px) {
    gap: 10px;
  }
`;

const TextWrapStyled = styled.div`
  max-width: 689px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-height: 414px) {
    gap: 10px;
  }
`;

const IconStyled = styled.img`
  max-width: 168px;

  @media (max-height: 414px) {
    max-width: 100px;
  }
`;

export default function ResultModal({ open, title, description }) {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <ModalView open={isOpen}>
      <FlexStyled>
        <IconStyled src={SuccessIcon} alt="Success" />
        <TextWrapStyled>
          <H3>{title}</H3>
          <TextLarge>{description}</TextLarge>
        </TextWrapStyled>
        <Button $width="312px" $height="48px" $onClick={closeModal}>
          {t('contactPageFormResultButton')}
        </Button>
      </FlexStyled>
    </ModalView>
  );
}
