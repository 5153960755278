import React from 'react';
import styled, { css } from 'styled-components';

const ButtonTextStyled = styled.div`
  text-align: ${(props) => props.$align || 'center'};

  /* New/Button */
  font-family: 'Inter-SemiBold', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.32px;
  text-transform: uppercase;
  color: ${(props) => props.$color};

  &:hover {
    color: ${(props) => props.$hoverColor};
  }

  ${(props) =>
    props.$useMedia &&
    css`
      @media (max-width: 767px) {
        font-size: 14px;
        letter-spacing: 0.28px;
      }
    `}

  ${(props) =>
    props.$isActive &&
    css`
      color: ${(props) => props.$hoverColor};
    `}
`;

export default function ButtonText(props) {
  return <ButtonTextStyled {...props}>{props.children}</ButtonTextStyled>;
}
