export function getJobs() {
  return [
    {
      id: '1',
      slug: 'fullstack-developer',
      title: 'Fullstack Developer (JavaScript)',
      badge: 'new',
      requirements: [
        'At least 1 year of commercial experience',
        'Experience with Vue.js or React.js (at least one of those)',
        'JavaScript, TypeScript',
        'Knowledge: Node.js / Express',
        'Bootstrap',
        'OOP principles',
        'Git',
        'Unit Tests',
        'REST',
        'Docker',
        'Experience with one of the tools: Matlab, mathjs, algebrajs, or any other JS mathematics libraries',
        'English language — Upper-Intermediate level',
      ],
      optional: [
        'Experience with server-side setup (Amazon, Google Cloud)',
        'GraphQL',
      ],
      offers: [
        'Competitive salary',
        'Regular inspection of wages and qualifications',
        'Official employment',
        'Professional development',
        'Friendly staff',
        'Conferences, certifications, master classes paid by company',
        'Free English lessons with a certified English teacher',
        'You can work from an office in Lviv in the city center / remotely',
        'Corporate events, team-building, office parties and Pizza Day',
      ],
      meta: {
        title: 'Fullstack Developer (JavaScript)',
        description: 'Fullstack Developer (JavaScript).',
      },
    },
    {
      id: '2',
      slug: 'android-developer',
      title: 'Android Developer',
      badge: 'hot',
      requirements: [
        '2+ years of commercial experience',
        'Knowledge: Android SDK, Java, Kotlin, OOP/OOD, Rest, XML, SQLite DB',
        'Retrofit, Dagger/Koin, Android Jetpack',
        'WorkManager, Navigation, Room',
        'Versioning tools (GitLab, GitHub, Bitbucket)',
        'English — at least Intermediate level',
      ],
      optional: [
        'RxJava/RxKotlin',
        'Coroutines',
        'Clean Architecture, MVVM, MVP, DataBinding',
        'Firebase',
      ],
      offers: [
        'Competitive salary',
        'Regular inspection of wages and qualifications',
        'Official employment',
        'Professional development',
        'Friendly staff',
        'Conferences, certifications, master classes paid by company',
        'Free English lessons with a certified English teacher',
        'You can work from an office in Lviv in the city center / remotely',
        'Corporate events, team-building, office parties and Pizza Day',
      ],
      meta: {
        title: 'Android Developer',
        description: 'Android Developer.',
      },
    },
    {
      id: '3',
      slug: 'ios-developer',
      title: 'iOS Developer',
      badge: 'hot',
      requirements: [
        '2+ years of commercial experience',
        'Knowledge: iOS SDK, Swift',
        'Xcode, OOP/OOD, HTTP, OAuth, JSON, REST, Unit tests',
        'MVC/MVVM',
        'Versioning tools (GitLab, GitHub, Bitbucket)',
        'English — at least Intermediate level',
      ],
      optional: ['Objective-C', 'Firebase'],
      offers: [
        'Competitive salary',
        'Regular inspection of wages and qualifications',
        'Official employment',
        'Professional development',
        'Friendly staff',
        'Conferences, certifications, master classes paid by company',
        'Free English lessons with a certified English teacher',
        'You can work from an office in Lviv in the city center / remotely',
        'Corporate events, team-building, office parties and Pizza Day',
      ],
      meta: {
        title: 'iOS Developer',
        description: 'iOS Developer.',
      },
    },
    {
      id: '4',
      slug: 'node-developer',
      title: 'Node.js Developer',
      badge: 'hot',
      requirements: [
        'At least 6 months of commercial experience',
        'Knowledge: Node.js, TypeScript, MySQL, MongoDB',
        'Knowledge: Nest.js, Express.js (at least one of those)',
        'Versioning tools (GitLab, GitHub, Bitbucket)',
      ],
      optional: [
        'HTML5, CSS3, Bootstrap',
        'Experience with server setup (AWS, Azure, Google Cloud)',
        'Experience with Rest API',
      ],
      offers: [
        'Competitive salary',
        'Regular inspection of wages and qualifications',
        'Official employment',
        'Professional development',
        'Friendly staff',
        'Conferences, certifications, master classes paid by company',
        'Free English lessons with a certified English teacher',
        'You can work from an office in Lviv in the city center / remotely',
        'Corporate events, team-building, office parties and Pizza Day',
      ],
      meta: {
        title: 'Node.js Developer',
        description: 'Node.js Developer.',
      },
    },
    {
      id: '5',
      slug: 'qa-engineer',
      title: 'QA Engineer',
      requirements: [
        '1+ year of experience',
        'Knowledge of QA methodologies/tools/processes',
        'Knowledge of software development life-cycle, quality process',
        'Experience with test documentation (test cases, test plan)',
        'Experience with JIRA & Slack',
        'Experience with mobile & web testing',
      ],
      optional: ['Java/Selenium experience'],
      offers: [
        'Competitive salary',
        'Regular inspection of wages and qualifications',
        'Official employment',
        'Professional development',
        'Friendly staff',
        'Conferences, certifications, master classes paid by company',
        'Free English lessons with a certified English teacher',
        'You can work from an office in Lviv in the city center / remotely',
        'Corporate events, team-building, office parties and Pizza Day',
      ],
      meta: {
        title: 'QA Engineer',
        description: 'QA Engineer.',
      },
    },
  ];
}
