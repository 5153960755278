export function getFilterNames() {
  return [
    'Mobile development',
    'Web Development',
    'UI/UX Design',
    'Social',
    'Health Care',
    'Education',
    'Entertainment',
    'Travel',
    'Hiking',
  ];
}
