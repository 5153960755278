import Circle from '../figures/Circle';

export default function I_LETTER() {
  return (
    <span className="letter-I">
      <Circle className="letter-I-circle" styles={{ $active: 1 }} />
      <span className="hidden-I">I</span>
    </span>
  );
}
