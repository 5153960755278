import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import ErrorIcon from '../../assets/contact/error-icon.svg';

const Input = ({
  name,
  type,
  value,
  label,
  placeholder,
  onChange,
  error,
  ...rest
}) => {
  const hasError = error ? true : false;
  const [showError, setErrorVisibility] = useState(false);
  const handleError = (showError) => setErrorVisibility(showError);

  delete rest.onFocus;
  delete rest.onBlur;

  return (
    <div className="input__group">
      {label === 'Linkedin' && (
        <div className="text-tiny" style={{ marginTop: '24px' }}>
          *By sending this, you confirm acknowledgment of our{' '}
          <Link to="/privacy-policy" className="text-tiny__link">
            Privacy Policy
          </Link>
          .
        </div>
      )}

      {showError && (
        <div className="input__error">
          <img src={ErrorIcon} alt="Error" /> {error}
        </div>
      )}

      <input
        name={name}
        id={name}
        type={type}
        className={classnames('form-input', { 'has-error': showError })}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        autoComplete="off"
        onFocus={() => handleError(false)}
        onBlur={() => handleError(hasError)}
        {...rest}
      />

      <label htmlFor={name} className="form-input__label">
        {label}
      </label>
    </div>
  );
};

export default Input;
