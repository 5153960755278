import { useTranslation } from 'react-i18next';

import ItemCard from '../../../components/cards/ItemCard';
import TextLarge from '../../../components/typography/TextLarge';
import ButtonText from '../../../components/typography/ButtonText';
import InputText from '../../../components/typography/InputText';
import SecondaryText from '../../../components/typography/SecondaryText';
import H3 from '../../../components/typography/H3';
import { isEnglish } from '../../../utils/helpers';

export default function ReviewItem({ review }) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { rate, teamSize, text, author, cooperationTime } = review;

  return (
    <ItemCard
      $padding="48px 48px"
      $margin="0 auto"
      $width="100%"
      $height="100%"
      $alignItems="stretch"
      $gap="64px"
      className="client-item-card"
    >
      <div className="review-summary">
        <div className="rating">
          <div className="review__source-mobile">
            <a href={review.source} target="_blank" rel="noopener noreferrer">
              <img
                className="clutch-logo"
                src="img/clutch-logo.svg"
                alt="Clutch Logo"
              />
            </a>
          </div>

          <H3 $margin="0 8px 0" $color="#E1F1FF">
            {rate}
          </H3>
          <Stars />
        </div>

        <div className="review__source">
          <a href={review.source} target="_blank" rel="noopener noreferrer">
            <img
              className="clutch-logo"
              src="img/clutch-logo.svg"
              alt="Clutch Logo"
            />
          </a>
        </div>

        <div className="review-details">
          <Detail name={t('homePageClientsCompany')} detail={author.company} t={t} />
          <Detail name={t('homePageClientsTeamSize')} detail={teamSize || 5} t={t} />
          <Detail
            name={t('homePageClientsCooperationTime')}
            detail={cooperationTime || 1}
            language={language}
            t={t}
          />
        </div>
      </div>

      <div className="review-description">
        <img src="img/icon-quote.svg" alt="Quote Icon" />

        <div className="review-description__info">
          <TextLarge $align="left" $color="#F3F8FA">
            {text}
          </TextLarge>

          <ButtonText $align="left" $color="#50C5EA" $useMedia>{`${
            author.name || 'Anonymous'
          }, ${author.position}, ${author.company}`}</ButtonText>
        </div>
      </div>
    </ItemCard>
  );
}

function Stars() {
  return (
    <div className="stars">
      <img src="/icons/star.svg" alt="star" />
      <img src="/icons/star.svg" alt="star" />
      <img src="/icons/star.svg" alt="star" />
      <img src="/icons/star.svg" alt="star" />
      <img src="/icons/star.svg" alt="star" />
    </div>
  );
}

function Detail({ name, detail, language, t }) {
  let info = detail;
  const isPlural = detail >= 2;

  if (name === t('homePageClientsTeamSize')) {
    if (!isEnglish(language) && (detail === 5 || detail === 8)) {
      info += ' спеціалістів';
    } else {
      info += isPlural
        ? ` ${t('homePageClientsSpecialists')}`
        : ` ${t('homePageClientsSpecialist')}`;
    }
  }

  if (name === t('homePageClientsCooperationTime')) {
    if (!isEnglish(language) && detail === 4) {
      info += ' роки';
    } else {
      info += isPlural
        ? ` ${t('homePageClientsYears')}`
        : ` ${t('homePageClientsYear')}`;
    }
  }

  return (
    <InputText $align="right" $useMedia className="review-details__item">
      <SecondaryText $color="#B2B2B2">{name}:</SecondaryText> {info}
    </InputText>
  );
}
