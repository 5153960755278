import React from 'react';
import Joi from 'joi';
import Form from '../../components/forms/Form';
import InputMask from 'react-input-mask';

class ApplyForm extends Form {
  state = {
    data: {
      fullName: '',
      email: '',
      phone: '',
      location: '',
      linkedin: '',
    },
    errors: {},
    stage: {
      sending: false,
      success: false,
      error: false,
    },
  };

  // Validation requirements
  schemaProps = (propName) => {
    const schemaProps = {
      fullName: Joi.string().required().label('Full Name'),
      email: Joi.string()
        .email({ tlds: { allow: false } })
        .message('Invalid email')
        .required()
        .label('Email'),
      phone: Joi.string().min(17).required().label('Phone').messages({
        'string.min': '"Phone" must be a valid phone number',
      }),
      location: Joi.string().required().label('Location'),
      linkedin: Joi.string().required().label('Linkedin'),
    };

    return propName ? schemaProps[propName] : schemaProps;
  };

  schema = Joi.object(this.schemaProps());

  // for Linkedin Field - removing unnecessary part of URL and getting only Username
  handleReplace = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    const value = input.value;
    const regex =
      // eslint-disable-next-line
      /((https?)\:\/\/([\w-]+\.)?([\w-])+\.(\w)+\/?[\w\?\.\=\&\-\#\+\/]+)[a-z]{1,6}\b(\/in\/)/g;
    const inputValue = value.replace(regex, '');
    data[input.name] = inputValue;
    this.setState({ data });
  };

  // Submiting to server
  doSubmit = () => {
    console.log('Submit');

    const data = { ...this.state.data, jobTitle: this.props.jobTitle };
    const { REACT_APP_TEMPLATE_APPLY_FORM } = process.env; // ID of Template for Apply Form
    return { data, templateId: REACT_APP_TEMPLATE_APPLY_FORM };
  };

  render() {
    const { data, errors } = this.state;

    return (
      <form className="form" onSubmit={this.handleSubmit} id="applyForm">
        <div className="form__fields">
          {this.renderInput('fullName', 'Full Name', 'Your Full Name')}
          {this.renderInput('email', 'Email', 'your@email.com')}

          <InputMask
            mask={'+380 99 999 99 99'}
            maskChar={null}
            value={data.phone}
            onChange={this.handleChange}
            error={errors.phone}
          >
            {(inputProps) =>
              this.renderInput('phone', 'Phone', 'Your Phone', 'text', {
                ...inputProps,
              })
            }
          </InputMask>

          {this.renderInput('location', 'Location', 'Your City')}

          {this.renderInput(
            'linkedin',
            'Linkedin',
            'Your Linkedin Profile',
            'text',
            { onKeyUp: this.handleReplace }
          )}
        </div>

        {this.renderButton('Apply')}
        {this.renderMessage()}
      </form>
    );
  }
}

export default ApplyForm;
