import { useParams } from 'react-router-dom';
import NotFoundRedirect from '../../utils/notFoundRedirect';

import caseStudies from '../../services/caseStudiesList';
import CaseItem from './CaseItem/CaseItem';

export default function CaseStudiesRedirect() {
  const { slug } = useParams();
  const project = caseStudies.find((p) => p.slug === slug);

  if (!project) return <NotFoundRedirect />;

  return <CaseItem project={project} />;
}
