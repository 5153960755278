export const Constants = {
  MAIN_TECHNOLOGIES: {
    ANDROID: 'Android App',
    IOS: 'IOS App',
    KOTLIN: 'Kotlin',
    PHP: 'PHP',
    NODE: 'Node.js',
    VUE: 'Vue.js',
    SHAREPOINT: 'Sharepoint',
    JAVA_SCRIPT: 'JavaScript',
    C_SHARP: 'C# / .Net',
    FIREBASE: 'Firebase',
    SWIFT: 'Swift / RxSwift',
    MONGO: 'MongoDB / MySQL',
    JAVA: 'Java / RxJava',
    REST: 'REST API',
    OBJECTIVE: 'Objective C',
    HTML_CSS: 'HTML5 / CSS3',
  },

  SERVICES_DIRECTIONS_EN: [
    'MOBILE DEVELOPMENT',
    'web development',
    'innovation technologies',
    'business support',
  ],
  SERVICES_DIRECTIONS_UA: [
    'Мобільна Розробка',
    'Веб Розробка',
    'Інноваційні Технології',
    'Підтримка бізнес рішень',
  ],

  INNOVATION_TABS_DATA_EN: [
    {
      id: 1,
      name: 'IoT',
      title: 'The Internet of things',
      text: `has come a long way from large industrial systems to everyday home usage. No one is surprised by a “smart” vacuum cleaner or light bulb anymore, and that makes it quite intriguing to be a part of this industry. We can help you turn analog products into digital ones with the power of innovations.`,
    },
    {
      id: 2,
      name: 'Machine Learning',
      title: 'Machine Learning',
      text: `is used in Computer Vision, Voice/Speech Recognition (Siri, Google Assistant), and various AI-powered chatbots. These are not the only areas of usage, but certainly the most popular ones. Our team has worked with all of them before, so we can provide guidance and support in these areas.`,
    },
    {
      id: 3,
      name: 'Blockchain',
      title: 'Blockchain Technology',
      text: `can be used everywhere: from peer-to-peer transactions to contracts and documentation. Of course, one of the most popular areas of usage is cryptocurrency, NFT,  and related products. Our team of Blockchain experts can help you integrate this technology into your solution as well.`,
    },
    {
      id: 4,
      name: 'Image Recognition',
      title: 'Augmented and Virtual Reality technologies (AR/VR)',
      text: `are used inside games, social networks, and even hardware solutions. Recognize objects, markers, or even gestures; create 360 tours of the objects and properties - everything is possible here. AR/VR allows you to present content in an engaging and the most appealing way for end users. Deviark can help you understand the power of these technologies and their benefits for your business.`,
    },
    {
      id: 5,
      name: 'AI',
      title: 'AI (Artificial Intelligence)',
      text: `is widely recognized for its role in various fields, including machine learning, natural language processing, and computer vision. However, its applications extend far beyond these domains, encompassing a diverse range of industries. These applications involve intelligent automation, data analytics, and problem-solving capabilities. Our team of AI specialists is here to assist you in implementing this cutting-edge technology into your project, enabling you to harness the power of AI for enhanced efficiency and innovation.`,
    },
  ],

  INNOVATION_TABS_DATA_UA: [
    {
      id: 1,
      name: 'IoT',
      title: 'ІНТЕРНЕТ РЕЧЕЙ',
      text: `пройшов довгий шлях від складних індустріальних систем до щоденного використання вдома у кожного з нас. Тепер важко когось здивувати “розумним” порохотягом або лампою, і саме це робить дану сферу досить цікавою та інноваційною.`,
    },
    {
      id: 2,
      name: 'Машинне навчання',
      title: 'Машинне навчання',
      text: `використовується у таких сферах як: Комп’ютерний Зір (Computer Vision), Розпізнавання Голосу та Мови (Voice and Speech Recognition), а також у різноманітних ШІ-ботах. Наша команда має досвід в усіх цих технологіях.`,
    },
    {
      id: 3,
      name: 'Блокчейн',
      title: 'Блокчейн (Blockchain) технологія',
      text: `використовується у різноманітних сферах: від фінансових транзакцій, до контрактів та документації. Звісно найбільш популярна сфера використання це криптовалюта, NFT та суміжні продукти. Наші блокчейн спеціалісти можуть допомогти інтегрувати ці технології у Ваш чинний продукт або бізнес.`,
    },
    {
      id: 4,
      name: 'Штучний Інтелект',
      title: 'Штучний Інтелект (AI) ',
      text: `увірвався у наше життя з надзвуковою швидкістю і вже встиг змінити багато речей з якими ми стикаємось щодня. Відтепер можна отримати відповідь на будь-яке запитання протягом декількох секунд, створити зображення або колаж лише за допомогою одного запиту, та навіть розпізнати предмет лише за допомогою камери смартфона. Усе це стало можливим завдяки ШІ сервісам, яких з кожним днем стає все більше. Наша команда має значний досвід у розробці ШІ рішень, як для мобільних додатків, так і для вебсайтів. І ми з радістю допоможемо Вам змінити ваш бізнес за допомогою штучного інтелекту.`,
    },
  ],

  CASE_STUDIES_EN: [
    {
      id: 1,
      slug: 'trail-tutor',
      imgLow: 'trail-tutor-low.png', // image name from public/img/case-studies folder
      imgHigh: 'trail-tutor-high.png',
      title: 'TRAIL TUTOR',
      description:
        'A mobile platform for an outdoor enthusiasts and hiking/trekking.',
    },
    {
      id: 2,
      slug: 'plum',
      imgLow: 'plum-low.png',
      imgHigh: 'plum-high.png',
      title: 'Plum',
      description:
        'A food/health application, created to fight obesity and help people with diabetes.',
    },
    {
      id: 3,
      slug: 'buzzer',
      imgLow: 'buzzer-low.png',
      imgHigh: 'buzzer-high.png',
      title: 'Buzzer',
      description:
        "The world's first social media platform where you get paid to go viral.",
    },
  ],

  CASE_STUDIES_UA: [
    {
      id: 1,
      slug: 'trail-tutor',
      imgLow: 'trail-tutor-low.png', // image name from public/img/case-studies folder
      imgHigh: 'trail-tutor-high.png',
      title: 'TRAIL TUTOR',
      description:
        'Додаток для походів у гори, розроблений для ринку США.  Дозволяє переглядати маршрути та відео в офлайн форматі.',
    },
    {
      id: 2,
      slug: 'plum',
      imgLow: 'plum-low.png',
      imgHigh: 'plum-high.png',
      title: 'Plum',
      description:
        'Додаток для контролю споживання їжі, який дозволяє розпізнавати продукти за допомогою ШІ-рішення Passio.ai.',
    },
    {
      id: 3,
      slug: 'buzzer',
      imgLow: 'buzzer-low.png',
      imgHigh: 'buzzer-high.png',
      title: 'Buzzer',
      description:
        'Соціальний додаток для створення та шерингу відео, а також участі у голосуванні за найкраще відео за день/тиждень/місяць.',
    },
  ],

  INDUSTRIES_EN: [
    {
      key: 'healthCare',
      title: 'health care',
      description: 'Solutions for medical institutions and devices',
    },
    {
      key: 'ecommerce',
      title: 'ecommerce',
      description: 'Shopping applications with cart and payment integrations.',
    },
    {
      key: 'transportation',
      title: 'transportation',
      description: 'Solutions for drivers and dispatching services.',
    },
    {
      key: 'iot',
      title: 'iot',
      description: 'Integration with hardware tools and smart devices.',
    },
    {
      key: 'entertainment',
      title: 'entertainment',
      description: 'Social media solutions, video apps and games.',
    },
    {
      key: 'rental',
      title: 'rental',
      description: 'Real estate applications, and solutions for landlords.',
    },
  ],

  INDUSTRIES_UA: [
    {
      key: 'healthCare',
      title: 'МЕДИЦИНА',
      description: 'Рішення для медичних установ та медичних пристроїв.',
    },
    {
      key: 'ecommerce',
      title: 'Е-КОМЕРЦІЯ',
      description: 'Рішення для онлайн магазинів.',
    },
    {
      key: 'transportation',
      title: 'ЛОГІСТИКА',
      description: 'Рішення для водіїв та логістичної сфери.',
    },
    {
      key: 'iot',
      title: 'iot',
      description: 'Інтеграція з різноманітними системами та розумними пристроями.',
    },
    {
      key: 'entertainment',
      title: 'РОЗВАГИ',
      description: 'Соціальні мережі, додатки для створення відео, а також ігри.',
    },
    {
      key: 'rental',
      title: 'НЕРУХОМІСТЬ',
      description: 'Рішення для маклерів, агентів з продажу та орендарів.',
    },
  ],
};
