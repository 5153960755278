import { useState, useEffect } from 'react';
import classNames from 'classnames';
import FilterItem from './FilterItem';
import Button from '../../../components/buttons/Button';
import ButtonText from '../../../components/typography/ButtonText';
import { getFilterNames } from '../helpers';

const filterItems = getFilterNames();

export default function FilterModal({
  isModalOpen,
  closeModal,
  currentSelectedFilters,
  handleFilterCases,
}) {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    setSelectedFilters(currentSelectedFilters);
  }, [currentSelectedFilters]);

  const handleFilterSelect = (filter) => {
    setSelectedFilters((currentFilters) => {
      if (currentFilters.includes(filter)) {
        return currentFilters.filter((item) => item !== filter);
      }
      return [...currentFilters, filter];
    });
  };

  const handleModalClosing = () => {
    setIsClosing(true);

    setTimeout(() => {
      setIsClosing(false);
      closeModal();
    }, 300);
  };

  const handleFiltersApply = () => {
    if (selectedFilters.length) {
      handleFilterCases(selectedFilters);
    }
    handleModalClosing();
  };

  const handleFiltersReset = () => {
    setSelectedFilters([]);
    handleFilterCases('All');
  };

  return (
    <>
      {isModalOpen && (
        <div className={classNames('modal-overlay', { closing: isClosing })}>
          <div className={classNames('modal-content', { closing: isClosing })}>
            <ModalHeader handleModalClosing={handleModalClosing} />

            <ModalItems
              handleFilterSelect={handleFilterSelect}
              selectedFilters={selectedFilters}
            />

            <ModalButtons
              handleFiltersReset={handleFiltersReset}
              handleFiltersApply={handleFiltersApply}
            />
          </div>
        </div>
      )}
    </>
  );
}

function ModalHeader({ handleModalClosing }) {
  return (
    <div className="filter-modal-header">
      <ButtonText $align="left" $color="#F3F8FA">
        Filter
      </ButtonText>

      <span className="close" onClick={handleModalClosing}>
        &times;
      </span>
    </div>
  );
}

function ModalItems({ handleFilterSelect, selectedFilters }) {
  return (
    <div className="filter-modal-items">
      {filterItems.map((item, i) => {
        return (
          <FilterItem
            item={item}
            key={i}
            handleFilterCases={handleFilterSelect}
            isActiveForce={selectedFilters.find((filter) => filter === item)}
          />
        );
      })}
    </div>
  );
}

function ModalButtons({ handleFiltersReset, handleFiltersApply }) {
  return (
    <div className="filter-modal-buttons">
      <Button $secondary $onClick={handleFiltersReset}>
        Reset
      </Button>
      <Button $onClick={handleFiltersApply}>Apply</Button>
    </div>
  );
}
