import React from "react";

const Social = ({ classes }) => {
  return (
    <div
      className={`social d-flex justify-content-md-center align-items-center ${classes}`}
    >
      <div className="social__icon">
        <a
          href="https://www.facebook.com/deviarkcompany/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img src="/img/social/icon-fb.svg" alt="Deviark on Facebook" />
        </a>
      </div>
      <div className="social__icon">
        <a
          href="https://www.linkedin.com/company/deviark/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img src="/img/social/icon-linkedin.svg" alt="Deviark in Linkedin" />
        </a>
      </div>
      <div className="social__icon">
        <a
          href="https://instagram.com/deviark"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            src="/img/social/icon-instagram.svg"
            alt="Deviark on Instagram"
          />
        </a>
      </div>
    </div>
  );
};

export default Social;
