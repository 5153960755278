import React from 'react';
import styled from 'styled-components';

const H3Styled = styled.div`
  text-align: ${(props) => props.$align || 'center'};
  margin: ${(props) => props.$margin};

  /* New/H3 */
  font-family: 'Inter-SemiBold', sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0.32px;
  text-transform: uppercase;
  color: ${(props) => props.$color || '#F3F8FA'};

  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 28px;
    line-height: 32px;
    letter-spacing: 0.24px;
  }
`;

export default function H3(props) {
  return <H3Styled {...props}>{props.children}</H3Styled>;
}
