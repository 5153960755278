import { useTranslation } from 'react-i18next';

import Circle from '../../../components/figures/Circle';
import H4 from '../../../components/typography/H4';
import { Constants } from '../constants';

import { isChristmasToday } from '../../../utils/helpers';
import {
  CHRISTMAS_GREEN_COLOR,
  CHRISTMAS_PINK_COLOR,
} from '../../../utils/constants';

let circleStyles = { $position: 'relative' };
const isChristmas = isChristmasToday();

export default function ServicesDirections() {
  const {
    i18n: { language },
  } = useTranslation();
  const serviceDirections =
    Constants[`SERVICES_DIRECTIONS_${language.toUpperCase()}`];

  return (
    <div className="services-container">
      <div className="container ">
        <ul className="services-directions">
          {serviceDirections.map((name, i) => {
            if (isChristmas) {
              circleStyles = {
                ...circleStyles,
                $disableBgImage: 'none',
              };
              if (i === 0 || i === 2) {
                circleStyles['$bgColor'] = CHRISTMAS_GREEN_COLOR;
              }
              if (i === 1 || i === 3) {
                circleStyles['$bgColor'] = CHRISTMAS_PINK_COLOR;
              }
            }
            return (
              <li key={i} className="services-directions__item">
                <div className="circle-services">
                  <Circle styles={circleStyles} />
                </div>
                <H4 $useMedia>{name}</H4>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
