import { useTranslation } from 'react-i18next';
import Circle from '../../../components/figures/Circle';
import H2 from '../../../components/typography/H2';
import Paragraph from '../../../components/typography/Paragraph';

export default function ClientsTitle() {
  const { t } = useTranslation();

  return (
    <div className="container clients-title">
      <H2 $align="left">{t('homePageClientsTitle')}</H2>

      <div className="clients-title__body">
        <div className="clients-title__text">
          <Paragraph $maxWidth="488px" $align="left">
            {t('homePageClientsDescription')}
          </Paragraph>
        </div>

        <div id="client-rect">
          <Circle styles={{ $position: 'relative', $active: 1 }} />
        </div>
      </div>
    </div>
  );
}
