export const leaderShip = [
  {
    imgSrc: 'img/leadership/andrii-kruten.png',
    lead: { fullname: 'Andrii Kruten', position: 'Founder & CEO' },
    quote:
      'Business is all about the people behind it. It’s not just numbers and KPIs, it’s that special bond that you have with your colleagues, it’s the risks you’re willing to take for them and it’s that feeling of absolute happiness and consistency when everyone is at their place and working like one mechanism. And that is a great feeling.',
  },
  {
    imgSrc: 'img/leadership/valentyn-hal.jpg',
    lead: { fullname: 'Valentyn Hal', position: 'CPo / Senior IT Consultant' },
    quote:
      'In a world where wires meet aspirations and circuits spark possibilities, I find my optimism fueled by the symphony of technologies composing a better tomorrow, where progress harmonizes with hope.',
  },
];
