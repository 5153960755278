import ItemCard from '../../components/cards/ItemCard';
import TextLarge from '../../components/typography/TextLarge';
import ButtonText from '../../components/typography/ButtonText';
import classNames from 'classnames';

export default function LeaderItem({ leader }) {
  const { imgSrc, lead, quote } = leader;

  return (
    <ItemCard
      $padding="30px 30px"
      $margin="0 auto"
      $width="100%"
      $height="100%"
      $alignItems="stretch"
      $gap="30px"
      className="leader-item-card"
    >
      <div className="leader-item-card__photo">
        <div className="leader-item-card__photo-block">
          <img
            src={imgSrc}
            alt={lead.fullname}
            className={classNames('leader-item-card__photo-item', {
              'leader-item-card__photo-item__bigger':
                lead.fullname === 'Andrii Kruten',
            })}
          />
        </div>

        <ButtonText
          $align="left"
          $color="#50C5EA"
          $useMedia
          className="leader-fullname-position-mobile"
        >{`${lead.fullname}, ${lead.position}`}</ButtonText>
      </div>

      <div className="leader-item-card__description">
        <img src="img/icon-quote.svg" alt="Quote Icon" />

        <div className="leader-item-card__description-info">
          <TextLarge
            $align="left"
            $color="#F3F8FA"
            className="leader-item-card__description-info__text"
          >
            {quote}
          </TextLarge>

          <ButtonText
            $align="left"
            $color="#50C5EA"
            $useMedia
            className="leader-fullname-position"
          >{`${lead.fullname}, ${lead.position}`}</ButtonText>
        </div>
      </div>
    </ItemCard>
  );
}
