import React from 'react';
import techStackIcons from './tech-stack';
import InputText from '../typography/InputText';

export default function TechnologyItem({ children, name }) {
  return (
    <li className="technology__item">
      <img src={techStackIcons[name]} alt={children} />

      <InputText $useMedia $color="#F3F8FA">
        {children}
      </InputText>
    </li>
  );
}
