import { useEffect, useState } from 'react';
import classnames from 'classnames';
import InputText from '../../../components/typography/InputText';

export default function FilterItem({ item, handleFilterCases, isActiveForce }) {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive(isActiveForce);
  }, [isActiveForce]);

  const handleActiveState = () => {
    if (item === 'All' && isActive) return;

    setIsActive((currentState) => !currentState);
    handleFilterCases(item);
  };

  return (
    <div
      className={classnames('filter-item', { active: isActive })}
      onClick={handleActiveState}
    >
      <InputText $color={isActive ? '#50c5ea' : ''}>{item}</InputText>
    </div>
  );
}
