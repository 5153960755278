import Stars from './Stars';

export default function ClutchRating() {
  return (
    <div className="clutch-container__rating">
      <div className="clutch-container__rating-values">
        <a
          href="https://clutch.co/profile/deviark"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="rating-value">5.0</div>
        </a>

        <a
          href="https://clutch.co/profile/deviark"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Stars />
        </a>
      </div>

      <a
        href="https://clutch.co/profile/deviark"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="/icons/clutch-footer.svg"
          alt="Clutch"
          className="clutch-footer-logo"
        />
      </a>
    </div>
  );
}
