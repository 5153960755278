export function isChristmasToday() {
  const currentDate = new Date();

  const startDate = new Date(2023, 11, 20); // 20 грудня
  const endDate = new Date(2024, 0, 1); // 1 січня

  return currentDate >= startDate && currentDate < endDate;
}

export function truncateText(text, maxWidth) {
  if (text.length <= maxWidth) {
    return text;
  } else {
    return text.substring(0, maxWidth - 3) + '...';
  }
}

export function getDescMaxLength(itemWidth, setMaxDescLength) {
  if (itemWidth >= 180) {
    return setMaxDescLength(50);
  }
  if (itemWidth >= 200) {
    return setMaxDescLength(60);
  }
  if (itemWidth >= 230) {
    return setMaxDescLength(65);
  }
  if (itemWidth >= 250) {
    return setMaxDescLength(75);
  }
  if (itemWidth >= 329) {
    return setMaxDescLength(90);
  }
  if (itemWidth >= 350) {
    return setMaxDescLength(100);
  }
}

export function splitTextForLocalization(text) {
  const leftPart = text.split('<>')[0];
  const middlePart = text.split('<>')[1].split('</>')[0];
  const rightPart = text.split('</>')[1];
  return { leftPart, middlePart, rightPart };
}

export function isEnglish(language) {
  return language === 'en';
}
