import React from 'react';
import TechnologyItem from '../../../components/TechnologyItem/TechnologyItem';
import { Constants } from '../constants';

import { register } from 'swiper/element/bundle';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Grid } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/grid';

register();

const breakpoints = {
  440: {
    slidesPerView: 2.5,
  },
};

export default function Technology() {
  const techStack = Object.entries(Constants.MAIN_TECHNOLOGIES);

  return (
    <section className="container technology">
      <ul className="home-technology">
        {techStack.map(([key, value]) => {
          return (
            <TechnologyItem name={key} key={key}>
              {value}
            </TechnologyItem>
          );
        })}
      </ul>

      <div className="swiper home-technology-mobile">
        <Swiper
          slidesPerView={2}
          initialSlide={1}
          grid={{
            rows: 4,
          }}
          spaceBetween={12}
          modules={[Grid]}
          centeredSlides={true}
          centeredSlidesBounds={true}
          breakpoints={breakpoints}
        >
          {techStack.map(([key, value]) => {
            return (
              <SwiperSlide key={key}>
                <TechnologyItem name={key}>{value}</TechnologyItem>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </section>
  );
}
