import { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Card from '../../components/cards/Card';
import Circle from '../../components/figures/Circle';
import { truncateText, getDescMaxLength } from '../../utils/helpers';

import H4 from '../../components/typography/H4';
import Paragraph from '../../components/typography/Paragraph';
import InputText from '../../components/typography/InputText';

export default function AiPageItem({ aiInfo }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [maxDescLength, setMaxDescLength] = useState(45);
  const [isActive, setIsActive] = useState(0);
  const itemContainerRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const aiItemWidth = itemContainerRef?.current?.clientWidth;
    if (aiItemWidth) {
      getDescMaxLength(aiItemWidth, setMaxDescLength);
    }
  }, [windowWidth, itemContainerRef?.current?.clientWidth]);

  const handleMouseEnter = () => setIsActive(1);
  const handleMouseLeave = () => setIsActive(0);

  const { imgLow, imgHigh, title, description, slug, imgSrc, tags } = aiInfo;

  return (
    <div className="ai-page__item" ref={itemContainerRef}>
      <Link className="projects__item-page" to={`/ai/${slug}`}>
        <Card
          className="ai-page-card"
          $width="100%"
          $height="100%"
          $minHeight=""
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <AiImages
            title={title}
            imgLow={imgLow}
            imgHigh={imgHigh}
            imgSrc={imgSrc}
          />

          <AiTags tags={tags} />

          <AiDescription
            title={title}
            description={description}
            maxDescLength={maxDescLength}
          />
        </Card>

        <div className="circle-ai-page">
          <Circle styles={{ $position: 'relative', $active: isActive }} />
        </div>
      </Link>
    </div>
  );
}

function AiImages({ title, imgSrc }) {
  return (
    <div className="ai-page__images">
      <img src={`/img/ai/${imgSrc}`} alt={title} className="ai-page__image" />
    </div>
  );
}

function AiTags({ tags }) {
  return (
    <div className="ai-page__tags">
      {tags.map((tag) => {
        return (
          <InputText $color="#F3F8FA" className="ai-page__tag" key={tag}>
            {tag}
          </InputText>
        );
      })}
    </div>
  );
}

function AiDescription({ title, description, maxDescLength }) {
  return (
    <>
      <H4 $align="left" $margin="0 0 16px 0" className="ai-page-card-title">
        {title}
      </H4>
      <Paragraph
        $color="#F3F8FA"
        $mediaSmallFontSize="12px"
        className="ai-page__description"
      >
        {truncateText(description, maxDescLength)}
      </Paragraph>
    </>
  );
}
