import React from 'react';
import styled from 'styled-components';

const ParagraphStyled = styled.div`
  max-width: ${(props) => props.$maxWidth};
  text-align: ${(props) => props.$align};
  text-overflow: ${(props) => props.$textOverflow};

  /* New/Paragraph */
  font-family: 'Inter-Regular', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.18px;
  color: ${(props) => props.$color || '#E1F1FF'};

  @media (max-width: 767px) {
    font-size: ${(props) => props.$mediaSmallFontSize || '16px'};
    line-height: 24px;
  }
`;

export default function Paragraph(props) {
  return <ParagraphStyled {...props}>{props.children}</ParagraphStyled>;
}
