import React from 'react';
import cn from 'classnames';

export default function MenuButton({ isVisible, toggleNav }) {
  return (
    <button
      className={cn('d-md-none', { open: isVisible })}
      id="btnNav"
      aria-label="Menu Button"
      onClick={toggleNav}
    >
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </button>
  );
}
