export default function FilterCloseButton() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2 5H4.5M22 5H9.5" stroke="#50C5EA" strokeWidth="2" />
      <path d="M22 12H19.5M2 12H14.5" stroke="#50C5EA" strokeWidth="2" />
      <path d="M2 19H4.5M22 19H9.5" stroke="#50C5EA" strokeWidth="2" />
      <circle cx="7" cy="5" r="2" stroke="#50C5EA" strokeWidth="2" />
      <circle
        cx="3"
        cy="3"
        r="2"
        transform="matrix(-1 0 0 1 20 9)"
        stroke="#50C5EA"
        strokeWidth="2"
      />
      <circle cx="7" cy="19" r="2" stroke="#50C5EA" strokeWidth="2" />
    </svg>
  );
}
