import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './utils/scrollToTop';
import ScrollToAnchor from './utils/scrollToAnchor';
import './localization/i18n';
import GoogleAnalytics from './utils/googleAnalytics';

const root = createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <ScrollToTop>
      <ScrollToAnchor />
      <App />
    </ScrollToTop>
    <GoogleAnalytics />
  </BrowserRouter>
);
