import { useRef } from 'react';
import LeaderItem from './LeaderItem';
import ArrowButton from '../../components/buttons/ArrowButton';
import { leaderShip } from '../../services/leadershipList';

import { register } from 'swiper/element/bundle';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

register();

const viewPresets = {
  768: {
    slidesPerView: 1.1,
  },
  860: {
    slidesPerView: 1.2,
  },
  1000: {
    slidesPerView: 1.3,
  },
  1150: {
    slidesPerView: 1.6,
  },
  1441: {
    slidesPerView: 1,
  },
};

export default function Leadership() {
  const preparedLeaderShip = leaderShip.concat(leaderShip).concat(leaderShip);
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  return (
    <div className="our-team__leaders-leadership">
      <div className="our-team__leaders-leadership-container">
        <Swiper
          speed={600}
          centeredSlides={true}
          centeredSlidesBounds={true}
          slidesPerView={1}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          breakpoints={viewPresets}
          spaceBetween={30}
          loop
        >
          {preparedLeaderShip.map((leader, i) => {
            return (
              <SwiperSlide key={leader.lead.fullname + i}>
                <LeaderItem leader={leader} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>

      <div className="swiper-controls">
        <ArrowButton direction="left" ref={navigationPrevRef} />
        <ArrowButton direction="right" ref={navigationNextRef} />
      </div>
    </div>
  );
}
