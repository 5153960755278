const services = [
  {
    id: 'software',
    slug: 'software-development',
    label: 'Enterprise Software',
    caption:
      'We provide end-to-end solutions for businesses of all sizes, blending the latest technologies with a specific business goal in mind and enabling interconnectivity with other tools and software that most businesses use.',
    title: 'Enterprise Software Development',
    description:
      'Deviark provides business software solutions for processes optimization and automation, increasing productivity and reporting, integration with the existing programming infrastructure, and streamlining the workflows.',
    overview: {
      heading: {
        startTitle: 'Bring your company to a new level with',
        secondaryTitle: 'Deviark',
        endTitle: '',
      },
      description:
        'We deliver next-generation software solutions and extend the capabilities of the existing enterprise systems. We help you to ditch the inflexible workflows by integrating new and customizing existing business CRM, ERP, BPM, and SCM applications. Scale your software instead of hiring new people!',
    },
    details: {
      heading: 'Enterprise software development services',
      list: [
        {
          heading: 'Business Workflow Management',
          description:
            'The workflow automation will help to replace manual and paper-based processes in order to help the business to control the working process, time costs, and tasks in a single interactive system.',
        },
        {
          heading: 'Data Management',
          description:
            'We will help with the automation of data management by providing database development, user interface design, and development of any complexity.',
        },
        {
          heading: 'Supply Chain Management',
          description:
            'Enhancing traditional freight and distribution management by focusing on gathering and storing real-time data and providing a single point of contact for all data, increasing visibility, and running real-time analytics by using artificial intelligence systems.',
        },
        {
          heading: 'Mobile and Web Development',
          description:
            'Enterprise solutions on Mobile and Web platforms require a more responsible approach to reliability, fault and vulnerabilities tolerance, productivity, and cross-platform compatibility',
        },
        {
          heading: 'SharePoint Solutions',
          description:
            'Deviark’s best SharePoint architects and developers are qualified to create a tailored business solution from scratch for effective people, information and processes management.',
        },
        {
          heading: 'Reporting Services Management',
          description:
            'Automation and processing of data collections for continuous reporting via charts, diagrams, and documents. We can use existing and create new software reporting solutions.',
        },
      ],
    },
    technologies: {
      ANDROID: 'Android App',
      IOS: 'IOS App',
      KOTLIN: 'Kotlin',
      JAVA_SCRIPT: 'JavaScript',
      C_SHARP: 'C# / .Net',
      NODE: 'Node.js',
      VUE: 'Vue.js',
      SHAREPOINT: 'Sharepoint',
    },
    meta: {
      title: 'Enterprise Software Development Services - CRM, ERP, BPM and SCM',
      description:
        'Deviark enterprise software includes workflow automation, data, supply chain and reporting services management, SharePoint, mobile and web solutions.',
    },
  },
  {
    id: 'management',
    slug: 'project-management',
    label: 'Project Management',
    caption:
      'Many projects fail or do not realize their full potential because the Project Manager in charge is ill equipped or inexperienced. With Deviark you can access talent with the specialist knowledge to manage your particular project.',
    title: 'Project Management',
    description:
      'IT project management consulting services are aimed to help businesses successfully run and complete IT projects. Deviark offers a comprehensive set of project management consulting services to help you deliver IT initiatives at improved speed, within the established budget and quality.',
    overview: {
      heading: {
        startTitle: 'Tangible benefits from',
        secondaryTitle: 'Deviark’s',
        endTitle: 'project management',
      },
      description:
        'We define target KPIs together with our clients. These KPIs can be related to business outcomes from project management, software quality, development speed, and project costs. We also provide end-to-end project implementation (or implementation management only, if needed) and can support you with lacking competencies.',
    },
    details: {
      heading: 'IT Project management services we provide',
      list: [
        {
          heading: 'New Project Launch Consulting',
          description:
            'We advise how to organize the working process and further manage a big project to maximize its value.',
        },
        {
          heading: 'Consulting on Project Recovery',
          description:
            'We provide urgent help for projects that have started to go downhill. Next, we help to adjust or set up and run new project processes.',
        },
        {
          heading: 'Distributed Team Management',
          description:
            'Deviark’s experienced project managers bring the best toolset and expertise to establish the most transparent and reliable communication and reporting in the context of the distributed team.',
        },
        {
          heading: 'Teamwork Coordination',
          description:
            'We offer professional services for project management based on the recognized PMI Standards, using Scrum, Agile, and Kanban. Deviark’s project managers will steer the team to the project completion.',
        },
        {
          heading: 'Software Development Life-cycle Planning',
          description:
            'If you’ve ever struggled from the consequences of the multiple changes of requirements, uncontrolled task distribution, unclear quality controls, and unclear code delivery, then Deviark could be your lifeboat.',
        },
      ],
    },
    technologies: {
      PMI: 'PMI',
      KANBAN: 'Kanban',
      GIT: 'Git',
      AGILE: 'Agile',
      SCRUM: 'Scrum',
      CONFLUENCE: 'Confluence',
      JIRA: 'Jira',
      MS_PROJECT: 'Ms Project',
      REDMINE: 'Redmine',
      DB_TESTING: 'DB Testing',
    },
    meta: {
      title: 'IT Project Management Service by Deviark',
      description:
        'Our project management services include the following: turn-key consultaion and guidance how to launch a new project, how to recover a project after idle period, how to manage a distributed team, how to establish team work coordination using best PMI standards, and SDLC planning for Agile and Waterfull using Scrum.',
    },
  },
  {
    id: 'mobile',
    slug: 'mobile-development',
    label: 'Mobile Development',
    caption:
      'Smartphones are a significant part of our everyday life today. So why not use them for your business too? We can create a native, hybrid, or cross-platform application and make it work for you.',
    title: 'Mobile Development',
    description:
      'Deviark builds custom mobile applications and enterprise mobility solutions across all mobile platforms. We deliver full-cycle development services, including prototyping, UI/UX design, programming, quality assurance, and maintenance. We build for iOS, watchOS, iPadOS, Android.',
    overview: {
      heading: {
        startTitle: 'Robust',
        secondaryTitle: 'mobile app',
        endTitle: 'development',
      },
      description:
        'Deviark mobile development team has a solid track record of delivering native and cross-platform custom applications for Android and iOS. We specialize in building high-quality software ranging from simple mobile apps up to complex systems that depend on a reliable and scalable back-end and database infrastructure.',
    },
    details: {
      heading: 'Mobile development services we provide',
      list: [
        {
          heading: 'Native iOS App Development',
          description:
            'Our iOS applications help you make informed decisions and give you the flexibility to perform critical tasks with ease.',
        },
        {
          heading: 'Native Android App Development',
          description:
            'Our expert team can help deliver customized secure and robust native Android applications, including e-commerce augmented reality and integration applications.',
        },
        {
          heading: 'Mobile Backend Development',
          description:
            'Back-end server for your solution, database, API, and integration with third-party data sources – all these services are provided by Deviark Mobile team.',
        },
        {
          heading: 'Wearables and Smart Devices',
          description:
            'We have implemented mobile app development solutions for Apple TV, smartwatches, cars, and smart homes.',
        },
        {
          heading: 'Payment Integration',
          description:
            'Mobile development services to let customers choose among convenient payment options.',
        },
        {
          heading: 'App Store Optimization',
          description: `The higher your app ranks in an app store’s search results, the more visible it is to potential customers. We guide you on how to come up with the best titles, keywords, descriptions, and more strategies to increase your app visibility.`,
        },
      ],
    },
    technologies: {
      ANDROID: 'Android App',
      IOS: 'IOS App',
      KOTLIN: 'Kotlin',
      JAVA_SCRIPT: 'JavaScript',
      C_SHARP: 'C# / .Net',
      NODE: 'Node.js',
      VUE: 'Vue.js',
      SHAREPOINT: 'Sharepoint',
    },
    meta: {
      title: 'Mobile Development Services by Deviark',
      description:
        'We develop native iOS and Android applications with the backend and help to adapt them for smart devices and wearables. We integrate payment solutions in mobile applications and optimize them for App Store.',
    },
  },
  {
    id: 'web',
    slug: 'web-development',
    label: 'Web Development',
    caption:
      'Each software product needs an eye-catching and unique design. Our UI/UX specialists can create your brand identity, design application, or website and make sure that user experience corresponds with the highest industry standards.',
    title: 'Web Development Services',
    description:
      'A web development company can take many shapes and forms. Our development team knows various programming languages. We have plenty of website development experience. We are also well-versed in more than website development. We can create the most complex systems that will work on the web. We are adept at using automation to increase the efficiency of value-added services.',
    overview: {
      heading: {
        startTitle: 'Robust',
        secondaryTitle: 'Web',
        endTitle: 'Development',
      },
      description:
        'Deviark is a full-service web development company prepared to serve your needs in a wide range of industries. We have a team of seasoned professionals equipped to handle projects from the back end to the front end.',
    },
    details: {
      heading: 'Web development services we provide',
      list: [
        {
          heading: 'Front-end Web Development',
          description:
            'Front end development is a broad term used to describe the design of websites and web applications. Front end development encompasses web design of the user interface and user experience. The goal is to present a relevant and engaging visual experience for users. We use JavaScript, HTML5/CSS3, and other technologies to achieve this goal.',
        },
        {
          heading: 'Back-end Web Development',
          description:
            'The back end development is the heart of the application. That includes everything from database management to payment processing. Also, vital services that we offer include back end servers, APIs, and integration with third-party services. Without the back end, your app does not have full functionality.',
        },
        {
          heading: 'Database Processing and Development',
          description:
            'Deviark’s engineers can organize the interaction of your site with a database of any level of complexity. If you need high-quality PostgreSQL, Mongo, or MySQL database development, we can help you. A strong database is the backbone of the back end of any online project. It should be searchable, usable, and easy to maintain. A good database also complies with all industry standards and regulations. We can help you create it.',
        },
        {
          heading: 'Mobile Layouts Development',
          description:
            'Every company needs a convenient mobile website. We create clear, beautiful mobile layouts with responsive/broad functionality and features. Mobile browsers can handle complicated tasks, but they do that a bit differently than desktop ones. Interacting with a touch screen that can rotate on a whim is very different from interacting with a mouse and keyboard. Our developers can make sure your website works smoothly on any device.',
        },
        {
          heading: 'SaaS Solutions',
          description:
            'Our skilled developers can create a top-notch SaaS solution for your users. We`ll build a user-friendly, multi-user access, responsive interface, and a reliable payment system. SaaS is a popular business model in today`s tech world. We`re dedicated to helping you establish a scalable service that grows with your business.',
        },
        {
          heading: 'CMS Development',
          description:
            'Managing a complex website with frequent changes can be challenging. CMS development offers an efficient solution to save time and control costs. Content management system development can improve your website`s usability and responsiveness. It allows your staff to keep the content of the website up-to-date. CMS development can simplify the process of managing a website`s content and information, even if it is complex and extensive.',
        },
      ],
    },
    technologies: {
      ANDROID: 'Android App',
      IOS: 'IOS App',
      KOTLIN: 'Kotlin',
      JAVA_SCRIPT: 'JavaScript',
      C_SHARP: 'C# / .Net',
      NODE: 'Node.js',
      VUE: 'Vue.js',
      FIREBASE: 'Firebase',
      REST: 'REST API',
    },
    meta: {
      title: 'Web Development Services by Deviark',
      description:
        'We carry out web front-end and back-end development, along with database processing and adaptation of the web layouts to the mobile devices. Also we develop full-scaled Saas and CRM solutions.',
    },
  },
  {
    id: 'consulting',
    slug: 'it-consulting',
    label: 'IT Consulting',
    caption:
      'Being in business for a long time, we have gathered enormous experience and knowledge that we are willing to share with our clients. We will analyze your idea or current solution and recommend the best approach according to your specific needs and business area.',
    title: 'IT Consulting',
    description:
      'We can help you develop and execute a clear and strategic IT roadmap with priorities that are closely linked to business goals. We will work one-on-one with your team to understand your goals, business processes, and current information systems capabilities.',
    overview: {
      heading: {
        startTitle: '',
        secondaryTitle: 'Consulting',
        endTitle: 'services tailored to you',
      },
      description:
        'From ad-hoc, helpful advice to a fully managed design, implementation, and  workshops, we will support you as much or as little help as you need, with our tailored IT consulting services. So, whether you need just an initial consultation and guidance at a tactical level or a project delivery with further expertise and resources, we can help.',
    },
    details: {
      heading: 'IT Consulting services we provide',
      list: [
        {
          heading: 'Digital Strategy Consulting',
          description:
            'We help you to prepare a business case for IT initiatives with ROI in focus, build an IT roadmap for modern tech implementation, and define how to position an IT function in your company.',
        },
        {
          heading: 'Solution Consulting',
          description:
            'We analyze your requirements and help you to find the best solution in terms of the technological stack, architecture, and implementation methodology, ensuring quality and choosing an optimal sourcing model.',
        },
        {
          heading: 'PoC Development',
          description:
            'To demonstrate the feasibility of your idea, Deviark team will develop either a Prototype without code or an MVP (Minimum Viable Product) with a minimal feature set.',
        },
        {
          heading: 'Legacy Code Modernization',
          description:
            'Our software engineers will take full responsibility for maintaining the code of your legacy systems, modernizing it bit by bit while preserving its existing functionality and premium quality.',
        },
        {
          heading: 'Software Audit and Code Review',
          description:
            'Deviark team independently examines the software development processes to assist organizations in improving software quality and productivity.',
        },
        {
          heading: 'Workshops',
          description:
            'Deviark’s best specialists prepare and conduct workshops to showcase the top-notch IT solutions and contextualize its implementation in your company.',
        },
      ],
    },
    technologies: {
      ANDROID: 'Android App',
      IOS: 'IOS App',
      KOTLIN: 'Kotlin',
      JAVA_SCRIPT: 'JavaScript',
      C_SHARP: 'C# / .Net',
      NODE: 'Node.js',
      VUE: 'Vue.js',
      SHAREPOINT: 'Sharepoint',
    },
    meta: {
      title: 'IT Consulting Services by Deviark',
      description:
        'We help to prepare digital strategy, analyze the requirements, develop POC proof-of-concept models, assist in legacy code modernization, audit code and review software, and provide workshops on demand.',
    },
  },
  {
    id: 'uiux',
    slug: 'design',
    label: 'Design',
    caption:
      'Each software product needs an eye-catching and unique design. Our UI/UX specialists can create your brand identity, design application, or website and make sure that user experience corresponds with the highest industry standards.',
    title: 'Design',
    description:
      'We bridge the gap between design and technology by designing optimal, visually intuitive, appealing, and efficient user interfaces, and implement a number of design systems to solve user problems better. We provide UI/UX development services for mobile, web, and desktop applications.',
    overview: {
      heading: {
        startTitle: 'Turn your app into a',
        secondaryTitle: 'handy eye-candy',
        endTitle: '',
      },
      description:
        'We use the recognized principles of UI/UX, the most recognized software, and industrial design systems like Flat Design, Metro, and Material Design. At the same time, we continuously develop in the field of Artificial Intelligence, Virtual and Augmented Reality, and 3D Animation.',
    },
    details: {
      heading: 'Design services',
      list: [
        {
          heading: 'User Interface and Interaction Design',
          description:
            'We create all the visual design, interface layout, animations, palette, etc. Our experience ranges from corporate and campaign websites to apps and intranets, dashboard visualizations, and complex enterprise solutions.',
        },
        {
          heading: 'Graphic Design',
          description:
            'We create and combine symbols, icons, images, and fonts to form visual representations of ideas and messages in software Interfaces.',
        },
        {
          heading: 'User Experience Design',
          description:
            'Starting from a sketch, we optimize the structure of your software, build intuitively-structured pages and screens, and manage all interactions between a user and an application along with all the links and transitions.',
        },
        {
          heading: 'Responsive Design',
          description:
            'Deviark responsive design enables websites to easily “go mobile”, providing a much better user experience and achieving lower development and maintenance costs as a result.',
        },
        {
          heading: 'Product Design',
          description:
            'Different products, ideas and concepts will require different prototypes, depending on your overall aim, such as product design prototypes, sales prototypes, design validation prototypes, or working test mock-ups.',
        },
        {
          heading: 'Corporate Branding',
          description:
            'At Deviark we create many items for your branding strategy, like corporate identity system (logo, business cards, email, signature, invoice and Style Guide), marketing items (brochures, whitepapers), and presentations.',
        },
      ],
    },
    technologies: {
      MATERIAL_DESIGN: 'Material Design',
      SKETCH: 'Sketch',
      AXURE: 'Axure',
      FIGMA: 'Figma',
      FLAT_DESIGN: 'Flat Design',
      CARBON: 'Carbon',
      METRO_DESIGN: 'Metro Design',
      BOOTSTRAP: 'Bootstrap',
      GAME_DESIGN: 'Game Design',
      ADOBE: 'Adobe',
    },
    meta: {
      title: 'Design Services by Deviark',
      description:
        'We create user interfaces, interaction design, graphic design, user experience design. Also we provide corporate branding services and product design.',
    },
  },
  {
    id: 'staff',
    slug: 'it-staff-augmentation',
    label: 'IT Staff Augmentation',
    caption:
      'Flexible outsourcing strategy that enables you to hire skilled technical resources to your in-house development team globally on either a short or long-term basis and manage your augmented team directly.',
    title: 'IT Staff Augmentation',
    description:
      'Access Deviark’s pool of specialists to scale your business intelligently. We will find the most suitable cooperation model – from a single specialist per your requirements to the entire development center of any size in order to manage your software development and support existing processes.',
    overview: {
      heading: {
        startTitle: 'Find top-notch developer talents at',
        secondaryTitle: 'Deviark',
        endTitle: '',
      },
      description:
        'Depending on your specific needs, we compose our teams of software developers, business analysts, software architects, quality assurance engineers, and UI/UX designers. We help to reduce micromanagement, provide complete transparency and accountability, and integrate with your business and development processes.',
    },
    details: {
      heading: 'Staff augmentation options',
      list: [
        {
          heading: 'Extended Team',
          description:
            'An intelligent extension to your team, which guarantees the required development capacity, while you’re staying completely in charge of your development team.',
        },
        {
          heading: 'Dedicated Development Center',
          description:
            'Build your own development team at Deviark that will manage software development processes for you. Let us tailor your future team in the most transparent and efficient way.',
        },
        {
          heading: 'Project Outsourcing',
          description:
            'Take full advantage of Deviark’s expertise to build a custom solution on a “per-project” basis. You provide ideas and requirements and we deliver the product.',
        },
      ],
    },
    technologies: {
      ANDROID: 'Android App',
      IOS: 'IOS App',
      KOTLIN: 'Kotlin',
      JAVA_SCRIPT: 'JavaScript',
      C_SHARP: 'C# / .Net',
      NODE: 'Node.js',
      VUE: 'Vue.js',
      SHAREPOINT: 'Sharepoint',
    },
    meta: {
      title: 'Staff Augmentation Services by Deviark',
      description:
        'Deviark extends technical team with specialists, establish dedicated development centers and carry out outsourced projects.',
    },
  },
  {
    id: 'qa',
    slug: 'quality-assurance',
    label: 'Testing and QA',
    caption:
      'Quality is always a top priority when it comes to software. Our QA engineers specialize in both manual and automated testing, guaranteeing you that software works as expected and without a single glitch.',
    title: 'Testing and QA',
    description:
      'Our QA team runs various tests depending on the project specifics and ensures that the developed functionality meets business requirements, the code is bug-free and the app will be enjoyed by its users.',
    overview: {
      heading: {
        startTitle: 'Meticulous',
        secondaryTitle: 'attention',
        endTitle: 'to the smallest details',
      },
      description:
        'We follow test-driven software development practices. This way, Deviark helps companies reduce the cost of downtime between releases. We also use test automation frameworks, such as data-driven test automation and agile automation. Our QA teams have broad experience in continuous testing practices and test automation approaches, such as graphical user interface testing and API-driven testing.',
    },
    details: {
      heading: 'Our testing services include',
      list: [
        {
          heading: 'Automated Testing',
          description:
            'Reduces manual QA costs by up to 20%, by automating bug detection, eliminating human error, and testing the resilience of your software.',
        },
        {
          heading: 'Manual and Usability Testing',
          description:
            'We help you avoid the risk of creating highly functional software nobody likes using by checking every screen, clicking every button, and making sure it’s positive user experience.',
        },
        {
          heading: 'Load and Performance Testing',
          description:
            'We’ll help you test the resilience of your application under stress, optimize its responsiveness, and prepare it for future development and support through robust performance testing.',
        },
        {
          heading: 'Dedicated QA team',
          description:
            "Deviark's dedicated QA teams integrate smoothly with the software development cycles of our clients. With the extended team delivery model, you can quickly scale QA resources with minimal risks and effort.",
        },
        {
          heading: 'Compatibility Testing',
          description:
            'Deviark will perform meticulous testing of your program across different browsers, databases, hardware, server infrastructures, resolution displays, and devices.',
        },
        {
          heading: 'Firmware and Middleware Testing',
          description:
            'Every software application in use today relies on some level of middleware and firmware to deliver the functionality the user eventually sees. Our QA team could guarantee a smooth interaction between all parts of the project.',
        },
      ],
    },
    technologies: {
      POSTMAN: 'Postman',
      TEST_MANAGEMENT: 'Test Management',
      SPIRA_TEAM: 'Spira Team',
      SELENIUM: 'Selenium',
      SOAPUI: 'SOAPUI',
      TEST_NG: 'Test NG',
      JIRA: 'Jira',
      KATALON: 'Katalon Studio',
      J_METER: 'JMeter',
      DB_TESTING: 'DB Testing',
    },
    meta: {
      title: 'Quality Assuarance and Testing Services by Deviark',
      description:
        'We provide automated testing, manual and usability testing, load and performance testing, and compatibility testing. Also we test firmware and middleware. We can set up a complete dedicated QA team.',
    },
  },
];

export function getServices() {
  return services;
}
