import { useState } from 'react';
import Meta from '../../utils/appendMeta';
import HeaderBackground from '../../components/commons/HeaderBackground';
import Help from '../Home/Help/Help';
import Button from '../../components/buttons/Button';

import aiProjectsList from '../../services/aiProjectsList';

import Circle from '../../components/figures/Circle';
import H1 from '../../components/typography/H1';
import Paragraph from '../../components/typography/Paragraph';
import ChristmasHat from '../../assets/christmas-hat.png';
import { isChristmasToday } from '../../utils/helpers';
import AiPageItem from './AiPageItem';

const metaData = {
  title: 'Deviark Projects',
  description: 'Our latest projects, challenges and outcome for business.',
};

const isChristmas = isChristmasToday();

const AI = () => {
  const [showMore, setShowMore] = useState(false);

  function toggleShowMoreBtn() {
    setShowMore((state) => {
      if (state) {
        window.scrollTo(0, 0);
      }
      return !state;
    });
  }

  return (
    <>
      <Meta metaData={metaData} />

      <section className="page-content ai-page">
        <HeaderBackground />

        <div className="container">
          <AIHeader />
        </div>

        <AiItems aiProject={aiProjectsList.slice(0, 3)} />
        {showMore && (
          <>
            <AiItems aiProject={aiProjectsList.slice(3, 6)} />
            <AiItems aiProject={aiProjectsList.slice(6)} />
          </>
        )}

        <Button
          $secondary
          $margin="0 auto 20px"
          className="ai-button-mobile"
          $onClick={toggleShowMoreBtn}
        >
          {showMore ? 'Show Less' : 'Show More'}
        </Button>

        <Help />
      </section>
    </>
  );
};

export default AI;

function AIHeader() {
  return (
    <div className="section__head">
      <H1 $align="left" className="section__head-title">
        ARTIFICIAL <br /> INTELL
        <span className="letter-I">
          <Circle className="letter-I-circle" styles={{ $active: 1 }} />
          <span className="hidden-I">I</span>
          {isChristmas && (
            <img src={ChristmasHat} alt="Christmas Hat" className="christmas-hat" />
          )}
        </span>
        GENCE
      </H1>

      <Paragraph $maxWidth="640px">
        From developing cutting-edge algorithms and machine learning models to
        deploying AI-powered solutions in diverse domains, we are at the nexus of
        innovation, driving forward the boundaries of what is possible with
        intelligent systems.
      </Paragraph>
    </div>
  );
}

function AiItems({ aiProject }) {
  return (
    <>
      <div className="ai-page__container desktop">
        <div className="container">
          <div className="ai-page__items">
            {aiProject?.map((aiInfo) => {
              return <AiPageItem aiInfo={aiInfo} key={aiInfo.id} />;
            })}
          </div>
        </div>
      </div>

      <div className="ai-page__container tablet">
        <div className="container">
          <div className="ai-page__items">
            {aiProject?.map((aiInfo) => {
              return <AiPageItem aiInfo={aiInfo} key={aiInfo.id} />;
            })}
          </div>
        </div>
      </div>
    </>
  );
}
