import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const CookiesNotification = () => {
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    const notificationShown = localStorage.getItem('cookiesNotificationShown');
    setShowNotification(!notificationShown);
  }, []);

  const handleConfirmCookies = () => {
    localStorage.setItem('cookiesNotificationShown', 'true');
    setShowNotification(false);
  };

  return (
    showNotification && (
      <div id="cookiesNotification" className="cookies wrapper">
        <div className="cookies__content">
          <div>
            <div className="cookies__title">
              This website uses <Link to={'/privacy-policy'}>cookies</Link>
            </div>

            <div className="cookies__text">
              We would like to use cookies to better understand your use of this
              website. This enables us to improve your future user experience on our
              website and to provide you with interest-based advertising and tailored
              content on our website as outside our website by partners. You can
              change or revoke your cookie settings at any time.
            </div>
          </div>

          <button
            id="confirmCookies"
            aria-label="Confirm Using Cookies"
            className="btn btn__primary cookies__btn"
            onClick={handleConfirmCookies}
          >
            OK
          </button>
        </div>
      </div>
    )
  );
};

export default CookiesNotification;
