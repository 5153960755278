import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TabsContainerDesktop from './Tabs/TabsContainerDesktop';
import TabsContainerMobile from './Tabs/TabsContainerMobile';

import H2 from '../../../components/typography/H2';

export default function Innovations() {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabIndex) => setActiveTab(tabIndex);

  return (
    <section className="container innovations-container">
      <H2 className="innovations-container__header">
        {t('homePageInnovationsTitle')}
      </H2>

      <TabsContainerDesktop activeTab={activeTab} handleTabClick={handleTabClick} />
      <TabsContainerMobile />
    </section>
  );
}
