import { useParams } from 'react-router-dom';
import NotFoundRedirect from '../../utils/notFoundRedirect';

import aiProjectsList from '../../services/aiProjectsList';
import AiItem from './AiItem/AiItem';

export default function AiRedirect() {
  const { slug } = useParams();
  const project = aiProjectsList.find((p) => p.slug === slug);

  if (!project) return <NotFoundRedirect />;

  return <AiItem project={project} />;
}
