import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import NotFound from '../pages/NotFound/NotFound';

const NotFoundRedirect = () => {
  return (
    <Switch>
      <Redirect to="/not-found" component={NotFound} />
    </Switch>
  );
};

export default NotFoundRedirect;
