import React from 'react';
import { Link } from 'react-router-dom';
import Circle from '../../components/figures/Circle';
import Button from '../../components/buttons/Button';
import TextLarge from '../../components/typography/TextLarge';
import ButtonText from '../../components/typography/ButtonText';

const circlesDesktop = Array(195).fill();
const circlesMobile = Array(64).fill();
const activeCircles = [
  28, 29, 32, 36, 37,
  //
  40, 42, 44, 46, 48, 50,
  //
  53, 54, 55, 57, 59, 61, 62, 63, 68, 71, 76,
];

const NotFound = () => {
  return (
    <section className="not-found">
      <div className="container">
        <div className="not-found-mobile-bg"></div>

        <div className="not-found-wrapper">
          <div className="circles-404">
            <ul className="circles-desktop">
              {circlesDesktop.map((_, i) => {
                let styles = { $position: 'relative' };
                if (activeCircles.includes(i)) {
                  styles = {
                    ...styles,
                    $active: 1,
                  };
                }
                return <Circle key={i} styles={styles} />;
              })}
            </ul>

            <ul className="circles-mobile">
              {circlesMobile.map((_, i) => {
                return <Circle key={i} styles={{ $position: 'relative' }} />;
              })}
            </ul>
          </div>

          <div className="section__head text-center">
            <h1 className="heading">Page Not Found</h1>
            <TextLarge $align="left">
              We're sorry, but the page you were looking for could not be found. It
              may have been moved, renamed, or simply vanished into the digital
              abyss.
            </TextLarge>

            <ButtonText
              $color="#E1F1FF"
              $align="left"
              className="not-found-mobile-text"
            >
              Press to make a light!
            </ButtonText>

            <Link to="/" className="not-found__btn desktop">
              <Button $width="312px" $height="48px" className="not-found-button">
                Back to home
              </Button>
            </Link>
          </div>

          <Link to="/" className="not-found__btn mobile">
            <Button $width="312px" $height="48px" className="not-found-button">
              Back to home
            </Button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default NotFound;
