import React, { Component } from 'react';
import Input from '../commons/Input';
import Joi from 'joi';
import emailjs from 'emailjs-com';
import Textarea from '../commons/Textarea';
import Paragraph from '../typography/Paragraph';

class Form extends Component {
  state = {
    data: {},
    errors: {},
    stage: {
      sending: false,
      success: false,
      error: false,
    },
  };
  componentDidUpdate(prevProps) {
    if (prevProps.isEnglishLang !== this.props.isEnglishLang) {
      this.setState({
        data: {
          ...this.state.data,
          isChecked: this.props.isEnglishLang ? true : false,
        },
      });
    }
  }

  validateForm = () => {
    const { error } = this.schema.validate(this.state.data, { abortEarly: false });

    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateInput = ({ name, value }) => {
    const inputObj = { [name]: value?.trim() };
    const schema = Joi.object({ [name]: this.schemaProps(name) });
    const { error } = schema.validate(inputObj);
    return error ? error.details[0].message : null;
  };

  handleSubmit = (e) => {
    e.preventDefault();

    // Checking Errors
    const errors = this.validateForm();
    this.setState({ errors: errors || {} });
    if (errors) return;

    // If No Errors
    const { data, templateId } = this.doSubmit();
    const { REACT_APP_USER_ID, REACT_APP_SERVICE_ID } = process.env;

    // Get and Update Stage State
    const { stage } = this.state;
    stage.sending = !stage.sending;
    this.setState({ stage });

    emailjs.send(REACT_APP_SERVICE_ID, templateId, data, REACT_APP_USER_ID).then(
      (response) => {
        console.log('SUCCESS!', response.status, response.text);

        // Get and Update Stage State
        const { stage } = this.state;
        stage.sending = !stage.sending;
        stage.success = !stage.success;
        this.setState({ stage });

        // Reset Form Data
        this.resetForm();

        this.props.setIsModalOpen(true);

        // Show Success Message for 10 Seconds
        setTimeout(() => {
          const { stage } = this.state;
          stage.success = !stage.success;
          this.setState({ stage });
        }, 10000);
      },
      (err) => {
        console.log('FAILED...', err);

        // Get and Update Stage State
        const { stage } = this.state;
        stage.error = !stage.error;
        this.setState({ stage });

        // Show Success Error for 10 Seconds
        setTimeout(() => {
          const { stage } = this.state;
          stage.error = !stage.error;
          this.setState({ stage });
        }, 10000);
      }
    );
  };

  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    if (input.type === 'checkbox') {
      data[input.name] = input.checked; // Update isChecked based on checkbox state
    } else {
      data[input.name] = input.value;
    }

    const errors = { ...this.state.errors };
    const errorMessage = this.validateInput(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    this.setState({ data, errors });
  };

  resetForm = () => {
    const data = { ...this.state.data };
    for (const [key] of Object.entries(data)) {
      data[key] = '';
    }
    this.setState({ data, errors: {} });
  };

  // Render Methods
  renderInput(name, label, placeholder, type = 'text', ...rest) {
    const { data, errors } = this.state;

    let normalizedArray = rest;
    let onAction = normalizedArray.shift();

    return (
      <Input
        name={name}
        type={type}
        value={data[name]}
        label={label}
        placeholder={placeholder}
        onChange={this.handleChange}
        error={errors[name]}
        {...rest}
        {...onAction}
      />
    );
  }

  renderTextarea(name, label, placeholder, ...rest) {
    const { data, errors } = this.state;

    return (
      <Textarea
        name={name}
        value={data[name]}
        label={label}
        placeholder={placeholder}
        onChange={this.handleChange}
        error={errors[name]}
        {...rest}
      />
    );
  }

  renderButton(label = 'Send message', cb, isUA = false) {
    const { stage } = this.state;

    if (cb && stage.success) {
      cb();
    }

    return (
      <button
        className="btn form-input__button"
        aria-label="Apply"
        disabled={this.validateForm() || stage.sending}
      >
        {label}
        {stage.sending && this.renderSendingAnimation(isUA)}
      </button>
    );
  }

  renderSendingAnimation(isUA) {
    let processText = 'Sending . . .';
    if (isUA) {
      processText = 'Відправка . . .';
    }

    return (
      <span className="btn__animation">
        <span className="btn__animation__background"></span>
        <span className="btn__animation__label">{processText}</span>
      </span>
    );
  }

  renderMessage() {
    const { stage } = this.state;

    if (stage.error)
      return (
        <Paragraph className="form__message form__message--error">
          Service error occurred. Try again later.
          <span className="timeline"></span>
        </Paragraph>
      );
  }
}

export default Form;
