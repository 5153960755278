import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import ButtonText from '../typography/ButtonText';

const ButtonStyled = styled.button`
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
  display: flex;
  padding: ${(props) => props.$padding || '16px 40px'};
  margin: ${(props) => props.$margin};
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: none;
  border-radius: 2px;
  transition: all 0.25s ease;

  &:hover {
    background: ${(props) => props.$hoverBgColor || '#50c5ea'};
    color: #f3f8fa;

    ${(props) =>
      props.$useArrowHover &&
      css`
        img {
          filter: grayscale(1) brightness(4);
        }
      `}
  }

  &:active {
    background: #2a69b4;
    color: #f3f8fa;
  }

  @media (max-width: 359px) {
    width: 100%;
  }

  ${(props) =>
    props.$primary &&
    css`
      background: #f3f8fa;
      color: #f3f8fa;
    `}

  ${(props) =>
    props.$secondary &&
    css`
      background: none;
      border: 2px solid #50c5ea;
      color: #50c5ea;
    `}

  ${(props) =>
    props.$primaryDark &&
    css`
      background: #011730;
      color: #f3f8fa;
    `}
`;

const Button = forwardRef((props, ref) => {
  return (
    <ButtonStyled {...props} onClick={props.$onClick} ref={ref}>
      <ButtonText>{props.children}</ButtonText>
    </ButtonStyled>
  );
});

export default Button;
