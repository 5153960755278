import { useState } from 'react';
import { Link } from 'react-router-dom';
import ItemCard from '../../components/cards/ItemCard';
import Circle from '../../components/figures/Circle';
import H4 from '../../components/typography/H4';

import { isChristmasToday } from '../../utils/helpers';
import { CHRISTMAS_GREEN_COLOR, CHRISTMAS_PINK_COLOR } from '../../utils/constants';

let circleStyles = { $position: 'relative' };
const isChristmas = isChristmasToday();

export default function ServicesList({ services }) {
  return (
    <div className="services__list">
      {services.map((service, i) => (
        <ListItem service={service} key={service.id} i={i} />
      ))}
    </div>
  );
}

function ListItem({ service, i }) {
  const [isActive, setIsActive] = useState(0);

  const handleMouseEnter = () => {
    setIsActive(1);
  };

  const handleMouseLeave = () => {
    setIsActive(0);
  };

  if (isChristmas) {
    circleStyles = {
      ...circleStyles,
      $disableBgImage: 'none',
    };
    const greenCircleIndexes = [0, 3, 4, 7];
    if (greenCircleIndexes.includes(i)) {
      circleStyles['$bgColor'] = CHRISTMAS_GREEN_COLOR;
    } else {
      circleStyles['$bgColor'] = CHRISTMAS_PINK_COLOR;
    }
  }

  return (
    <div className="services__tile">
      <div className="circle-services-list">
        <Circle styles={{ ...circleStyles, $active: isActive }} />
      </div>

      <Link to={`/services/${service.slug}`}>
        <ItemCard
          $border="none"
          $justifyContent="start"
          className="services-card"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <H4 $useMedia>{service.label}</H4>
        </ItemCard>
      </Link>
    </div>
  );
}
