import HeaderBackground from '../../components/commons/HeaderBackground';
import OurTeamInfo from '../../components/OurTeam/OurTeamInfo';
import Position from './Position';
import Meta from '../../utils/appendMeta';

import { getJobs } from '../../services/jobsList';

import H1 from '../../components/typography/H1';
import Paragraph from '../../components/typography/Paragraph';
import ChristmasGift from '../../assets/christmas-gift.png';
import { isChristmasToday } from '../../utils/helpers';

const metaData = {
  title: 'Careers at Deviark',
  description: 'Join Deviark team',
};

const isChristmas = isChristmasToday();

const Careers = () => {
  const jobsList = getJobs();

  return (
    <div className="careers-section">
      <Meta metaData={metaData} />

      <section className="page-content">
        <HeaderBackground />

        <div className="container">
          <div className="section__head">
            <CareersHeader />

            <Paragraph className="careers-section__description">
              If you're looking for a friendly atmosphere, interesting projects,
              ability to improve your skills and do something that really matters -
              please don’t hesitate and send us your CV now!
            </Paragraph>
          </div>

          <div className="row careers__row">
            <div className="col-12 col-md-10 col-lg-8">
              <div className="careers__list">
                {jobsList.map((job, i) => (
                  <Position job={job} key={job.id} i={i} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      <OurTeamInfo />
    </div>
  );
};

export default Careers;

function CareersHeader() {
  return (
    <H1 className="careers-section__title">
      Car
      <span className="title-letter-e">
        e
        {isChristmas && (
          <img src={ChristmasGift} alt="Christmas Gift" className="christmas-gift" />
        )}
      </span>
      ers
    </H1>
  );
}
