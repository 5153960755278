import { useTranslation } from 'react-i18next';

import H4Secondary from '../../components/typography/H4Secondary';
import TextLargeSecondary from '../../components/typography/TextLargeSecondary';
import TextLarge from '../../components/typography/TextLarge';

import React from 'react';
import MailIcon from '../../assets/contact/mail-icon.svg';
import PhoneIcon from '../../assets/contact/phone-icon.svg';
import LocationIcon from '../../assets/contact/location-icon.svg';

export default function ContactInfo({ localTime }) {
  const { t } = useTranslation();

  return (
    <>
      <div className="contact-lines">
        <div className="contact-line">
          <img src={MailIcon} alt="mail" />
          <TextLarge>
            <a href="mailto:info@deviark.com" className="contact__link">
              info@deviark.com
            </a>
          </TextLarge>
        </div>

        <div className="contact-line">
          <img src={PhoneIcon} alt="phone" />
          <TextLarge>
            <a href="tel:+380675458476" className="contact__link">
              +38 (067) 545 84 76
            </a>
          </TextLarge>
        </div>

        <div className="contact-line">
          <img src={LocationIcon} alt="location" />
          <TextLarge>
            <a
              href="https://goo.gl/maps/o1TjAfXddRfXgn528"
              target="_blank"
              rel="noopener noreferrer"
              className="contact__link"
            >
              {t('contactPageCardLocation')}
            </a>
          </TextLarge>
        </div>
      </div>

      <div className="local-time">
        <TextLargeSecondary>{t('contactPageCardTime')}</TextLargeSecondary>{' '}
        <H4Secondary className="local-time-value">{localTime}</H4Secondary>
      </div>
    </>
  );
}
