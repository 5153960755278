import React from 'react';
import styled, { css } from 'styled-components';

const H5Styled = styled.div`
  text-align: ${(props) => props.$align || 'center'};

  /* New/H5 */
  font-family: 'Inter-SemiBold', sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.18px;
  text-transform: uppercase;
  color: ${(props) => props.$color || '#E1F1FF'};
  transition: all 0.25s ease;

  &:hover {
    color: ${(props) => props.$hoverColor};
  }

  ${(props) =>
    props.$isActive &&
    css`
      color: ${(props) => props.$hoverColor};
    `}

  @media (max-width: 410px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export default function H5(props) {
  return <H5Styled {...props}>{props.children}</H5Styled>;
}
