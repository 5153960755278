import { useEffect, useState } from 'react';
import Meta from '../../utils/appendMeta';
import Circle from '../../components/figures/Circle';
import CaseStudiesPageItem from './CaseStudiesPageItem';
import FiltersDesktop from './Filters/FiltersDesktop';
import FiltersMobile from './Filters/FiltersMobile';
import HeaderBackground from '../../components/commons/HeaderBackground';

import caseStudies from '../../services/caseStudiesList';

import H1 from '../../components/typography/H1';
import Paragraph from '../../components/typography/Paragraph';
import ChristmasHat from '../../assets/christmas-hat.png';
import { isChristmasToday } from '../../utils/helpers';

const metaData = {
  title: 'Deviark Projects',
  description: 'Our latest projects, challenges and outcome for business.',
};

const isChristmas = isChristmasToday();

const CaseStudies = () => {
  const [caseStudiesItem, setCaseStudiesItem] = useState(caseStudies);
  const [selectedFilters, setSelectedFilters] = useState([]);

  useEffect(() => {
    if (selectedFilters.length) {
      filterCases({ setCaseStudiesItem, selectedFilters });
    } else {
      setCaseStudiesItem(caseStudies);
    }
  }, [selectedFilters]);

  const handleAddToFilters = (newFilter) => {
    setSelectedFilters((currentFilters) => [...currentFilters, newFilter]);
  };

  const handleDeleteFromFilters = (oldFilter) => {
    setSelectedFilters((currentFilters) =>
      currentFilters.filter((filter) => filter !== oldFilter)
    );
  };

  const handleFilterCases = (selectedCase) => {
    if (selectedCase === 'All') {
      setSelectedFilters([]);
      return;
    }

    if (Array.isArray(selectedCase)) {
      setSelectedFilters(selectedCase);
      return;
    }

    if (selectedFilters.includes(selectedCase)) {
      handleDeleteFromFilters(selectedCase);
    } else {
      handleAddToFilters(selectedCase);
    }
  };

  return (
    <>
      <Meta metaData={metaData} />

      <section className="page-content case-studies-page">
        <HeaderBackground />

        <div className="container">
          <CaseStudiesHeader />
        </div>

        <FiltersDesktop handleFilterCases={handleFilterCases} />
        <FiltersMobile
          handleFilterCases={handleFilterCases}
          selectedFilters={selectedFilters}
        />

        {caseStudiesItem.length ? (
          <FilteredCaseItems caseStudiesItem={caseStudiesItem} />
        ) : null}
      </section>
    </>
  );
};

export default CaseStudies;

function CaseStudiesHeader() {
  return (
    <div className="section__head">
      <H1 $align="left" className="section__head-title">
        Case Stud
        <span className="letter-I">
          <Circle className="letter-I-circle" styles={{ $active: 1 }} />
          <span className="hidden-I">I</span>
          {isChristmas && (
            <img src={ChristmasHat} alt="Christmas Hat" className="christmas-hat" />
          )}
        </span>
        es
      </H1>

      <Paragraph $maxWidth="640px">
        Explore our transformative projects and exceptional results in diverse
        industries, highlighting the power of collaboration and innovation.
      </Paragraph>
    </div>
  );
}

function FilteredCaseItems({ caseStudiesItem }) {
  return (
    <>
      <div className="case-studies-page__container desktop">
        <div className="container">
          <div className="case-studies-page__items">
            {caseStudiesItem?.map((caseInfo) => {
              return <CaseStudiesPageItem caseInfo={caseInfo} key={caseInfo.id} />;
            })}
          </div>
        </div>
      </div>

      <div className="case-studies-page__container tablet">
        <div className="container">
          <div className="case-studies-page__items">
            {caseStudiesItem?.map((caseInfo) => {
              return <CaseStudiesPageItem caseInfo={caseInfo} key={caseInfo.id} />;
            })}
          </div>
        </div>
      </div>
    </>
  );
}

function filterCases({ setCaseStudiesItem, selectedFilters }) {
  setCaseStudiesItem(() => {
    return caseStudies.filter(({ tags }) =>
      selectedFilters.some((filter) => tags.includes(filter))
    );
  });
}
