import React from 'react';
import styled from 'styled-components';

const H2Styled = styled.div`
  max-width: ${(props) => props.$maxWidth};
  margin: ${(props) => props.$margin};
  text-align: ${(props) => props.$align || 'center'};

  /* New/H2 */
  font-family: 'Inter-SemiBold', sans-serif;
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: ${(props) => props.$color || '#F3F8FA'};

  @media (max-width: 1100px) {
    font-size: 30px;
    line-height: 32px;
    letter-spacing: 0.24px;
  }

  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.24px;
  }
`;

export default function H2(props) {
  return <H2Styled {...props}>{props.children}</H2Styled>;
}
