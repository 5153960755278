import IotImage from '../../../../assets/innovations/iot.svg';
import MlImage from '../../../../assets/innovations/ml.svg';
import BlockchainImage from '../../../../assets/innovations/blockchain.svg';
import IrImage from '../../../../assets/innovations/ir.svg';
import AiImage from '../../../../assets/innovations/ai.svg';

export default function ContentImage({ activeTab }) {
  let image;
  switch (activeTab) {
    case 1:
      image = <img src={IotImage} alt="THE INTERNET OF THINGS" />;
      break;
    case 2:
      image = <img src={MlImage} alt="MACHINE LEARNING" />;
      break;
    case 3:
      image = <img src={BlockchainImage} alt="BLOCKCHAIN TECHNOLOGY" />;
      break;
    case 4:
      image = <img src={IrImage} alt="Recognition" />;
      break;
    case 5:
      image = <img src={AiImage} alt="Artificial Intelligence" />;
      break;
    default:
      image = <></>;
  }
  return <div className="content-image-container">{image}</div>;
}
