import Meta from '../../utils/appendMeta';

import Promo from '../../components/commons/Promo';
import Circle from '../../components/figures/Circle';
import ServicesList from './ServicesList';
import HeaderBackground from '../../components/commons/HeaderBackground';

import H1 from '../../components/typography/H1';
import Paragraph from '../../components/typography/Paragraph';
import ChristmasHat from '../../assets/christmas-hat.png';
import { isChristmasToday } from '../../utils/helpers';

const metaData = {
  title:
    'Deviark Information Technology Services which Help Businesses to Derive Value from the Technologies',
  description:
    'Deviark services include development of enterprise software, web-based and mobile applications, IT staff augmentation, consulting and strategizing, design development, professional project management and quality assurance.',
};

const isChristmas = isChristmasToday();

const Services = ({ services }) => {
  return (
    <>
      <Meta metaData={metaData} />

      <section className="services-page">
        <HeaderBackground />

        <div className="container">
          <div className="section__head">
            <ServicesHeader />

            <Paragraph $maxWidth="640px">
              With our highly trained personnel, engineering team, consultants,
              professional project managers, and quality assurance specialists, we
              can create successful solutions for your business or idea. Our wide
              range of services varies from design creation or testing to IT
              consulting and management.
            </Paragraph>
          </div>

          <ServicesList services={services} />

          <Promo
            startText="WANT to KNOW WHY WE’RE"
            secondaryText="THE BEST"
            endText="AT WHAT WE DO ?"
            isActive={1}
          />
        </div>
      </section>
    </>
  );
};

export default Services;

function ServicesHeader() {
  return (
    <H1 $align="left" className="section__head-title">
      Serv
      <span className="letter-I">
        <Circle className="letter-I-circle" styles={{ $active: 1 }} />
        <span className="hidden-I">I</span>
        {isChristmas && (
          <img src={ChristmasHat} alt="Christmas Hat" className="christmas-hat" />
        )}
      </span>
      ces
    </H1>
  );
}
