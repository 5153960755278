const caseStudies = [
  {
    id: 1,
    slug: 'plum',
    title: 'Plum',
    tags: [
      'Mobile development',
      'Education',
      'UI/UX Design',
      'Web Development',
      'Health Care',
    ],
    imgSrc: 'plum-case.png',
    preview: 'plum-preview.gif',
    description: 'An AI-powered tool for scientific studies and field testing',
    platform: ['iOS'],
    industry: ['Health Care'],
    duration: '1 year',
    discovery:
      'Our team was approached by a University of Otago from New Zealand and they have asked us to develop an iOS application that would allow tracking of food consumption and improving the dietary assessment for diabetes and obesity. In order to do this we had to implement an image recognition library into the application (https://www.passio.ai/), so that users could “scan” the food via camera and see nutrition data.',
    solution:
      'An iOS application with Firebase backend and Passio.ai integration (for food recognition) The application was successfully launched and is now being used for field testing.',
    goals:
      'To understand what people are eating (food and drink) and use that data for scientific studies (to fight problems with obesity in the region).',
  },
  {
    id: 2,
    slug: 'buzzer',
    title: 'Buzzer',
    tags: [
      'Mobile development',
      'Social',
      'UI/UX Design',
      'Web Development',
      'Entertainment',
    ],
    imgSrc: 'buzzer-case.png',
    preview: 'buzzer-preview.gif',
    description:
      "The world's first social media platform where you get paid to go viral.",
    platform: ['iOS'],
    industry: ['Social / Entertainment'],
    duration: '1 year',
    discovery:
      'Our team was contacted by a client from the USA who needed to finish his already existing application and launch it onto the market.',
    solution:
      'We had to do a complete re-do of the code and current infrastructure while keeping the core functionality intact. As a result, our team has managed to launch the product onto the market and prepared a stable infrastructure for ongoing support.',
    goals:
      'Reuse existing code (partly done), fix all of the issues, and add new functionality. The solution was supposed to support 10k active users.',
  },
  {
    id: 3,
    slug: 'trail-tutor',
    title: 'Trail Tutor',
    tags: [
      'UI/UX Design',
      'Mobile development',
      'Web Development',
      'Hiking',
      'Travel',
    ],
    imgSrc: 'trail-tutor-case.png',
    preview: 'trail-tutor-preview.gif',
    description: 'A mobile platform for an outdoor enthusiasts and hiking/trekking.',
    platform: ['iOS'],
    industry: ['Travel'],
    duration: '1 year',
    discovery:
      'Our team has received a request to design and code an iOS application that would allow users to download hiking trails and maps and use them offline. Each trail was also supposed to have a set of checkpoints with videos.',
    solution:
      'An iOS application with MapBox integration (for offline maps) and Firebase for the back-end. The app downloads the map data and videos during 1st install and then checks for the updates frequently. CoreData library is being used for offline data storage on the device itself.',
    goals:
      'Create an application that could work in a completely offline mode (in a secluded areas), while being able to provide an access to most of the application’s features.',
  },
];

export default caseStudies;
