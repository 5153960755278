import { useRef } from 'react';
import ArrowButton from '../../components/buttons/ArrowButton';

import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default function TabsContainerMobile({ info }) {
  const urls = info.reduce(
    (acc, current) => (current.url ? [...acc, current.url] : acc),
    []
  );
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  return (
    <div className="tabs-container-mobile">
      <div className="tabs-container-mobile-wrapper">
        <Swiper
          speed={600}
          centeredSlides={true}
          centeredSlidesBounds={true}
          slidesPerView={1}
          spaceBetween={30}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          loop
        >
          {urls.map((url, i) => {
            return (
              <SwiperSlide key={i}>
                <img src={url} alt="Our team" className="our-team-img" />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>

      <div className="swiper-controls">
        <ArrowButton direction="left" ref={navigationPrevRef} />
        <ArrowButton direction="right" ref={navigationNextRef} />
      </div>
    </div>
  );
}
