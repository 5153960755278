import ItemCard from '../../components/cards/ItemCard';
import HeaderBackground from '../../components/commons/HeaderBackground';
import TeamPhotosMobile from './TeamPhotosMobile';
import Leadership from './Leadership';

import T_WORD from '../../components/letters/T_WORD';

import H1 from '../../components/typography/H1';
import H2 from '../../components/typography/H2';
import H4 from '../../components/typography/H4';
import Paragraph from '../../components/typography/Paragraph';

const info = [
  { url: 'img/our-team/our-team-1.png' },
  { url: 'img/our-team/our-team-2.png' },
  {
    number: 20,
    description: 'Developers, consultants, designers',
  },
  { number: 100, description: 'Completed projects' },
  { url: 'img/our-team/our-team-3.png' },
  { url: 'img/our-team/our-team-4.png' },
];

const values = [
  {
    id: 1,
    name: 'Innovation',
    description:
      "We embrace innovation as a catalyst for progress. We constantly seek new ways to push boundaries, challenge the status quo, and pioneer solutions that drive our clients' success.",
  },
  {
    id: 2,
    name: 'Excellence',
    description:
      'We take pride in delivering top-notch solutions that exceed expectations. From the quality of our code to the user experience we craft, we strive for excellence in every detail, ensuring our clients receive nothing but the best.',
  },
  {
    id: 3,
    name: 'Continuous Learning',
    description:
      'In the ever-evolving world of technology, learning is a constant journey. We have a thirst for knowledge and a commitment to staying ahead of the curve.',
  },
];

export default function Team() {
  return (
    <section className="our-team">
      <HeaderBackground />

      <OurTeamTitle />
      <OurTeamPhotos />
      <OurTeamValues />

      <div className="our-team__leaders">
        <div className="container">
          <H2 $align="left" $margin="0 0 32px 0">
            Leadership
          </H2>
        </div>

        <div className="container-fluid">
          <Leadership />
        </div>
      </div>
    </section>
  );
}

function OurTeamTitle() {
  return (
    <div className="our-team__title">
      <div className="container">
        <H1 className="our-team__title-header">
          Our <T_WORD end="eam" />
        </H1>

        <Paragraph className="our-team__title-description">
          Get to know the faces behind the code and the minds behind the strategy -
          the remarkable team that makes Deviark stand out in the IT industry
        </Paragraph>
      </div>
    </div>
  );
}

function OurTeamPhotos() {
  const reversedInfo = [...info];
  [reversedInfo[0], reversedInfo[1]] = [info[1], info[0]];

  return (
    <div className="our-team__photos">
      <div className="container">
        <H2 $align="left" $margin="0 0 32px 0">
          WHO WE ARE
        </H2>

        <div className="our-team__photos-mobile">
          <TeamPhotosMobile info={reversedInfo} />
        </div>

        <div className="our-team__photos-grid">
          {info.map((item, i) => {
            if (item.url) {
              return (
                <img
                  src={item.url}
                  alt="Our team"
                  key={i}
                  className="our-team-img"
                />
              );
            }
            return (
              <ItemCard
                $flexDirection="column"
                key={i}
                className="our-team__photos-card"
              >
                <H2>{item.number}+</H2>
                <Paragraph $align="center">{item.description}</Paragraph>
              </ItemCard>
            );
          })}
        </div>
      </div>
    </div>
  );
}

function OurTeamValues() {
  return (
    <div className="our-team__values">
      <div className="container">
        <H2 $align="left" $margin="0 0 32px 0">
          OUR VALUES
        </H2>

        <div className="our-team__values-grid">
          {values.map(({ id, name, description }) => {
            return (
              <ItemCard
                $flexDirection="column"
                key={id}
                className="our-team__values-card"
              >
                <div className="our-team__values-header">
                  <div className="values-number">{id}</div>
                  <H4>{name}</H4>
                </div>
                <Paragraph>{description}</Paragraph>
              </ItemCard>
            );
          })}
        </div>
      </div>
    </div>
  );
}
