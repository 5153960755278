import ItemCard from '../../../components/cards/ItemCard';
import H4 from '../../../components/typography/H4';
import Paragraph from '../../../components/typography/Paragraph';

import HealthCareImg from '../../../assets/industries/health-care.svg';
import EcommerceImg from '../../../assets/industries/ecommerce.svg';
import TransportationImg from '../../../assets/industries/transportation.svg';
import IotImg from '../../../assets/industries/iot.svg';
import EntertainmentImg from '../../../assets/industries/entertainment.svg';
import RentalImg from '../../../assets/industries/rental.svg';

const images = {
  healthCare: HealthCareImg,
  ecommerce: TransportationImg,
  transportation: EcommerceImg,
  iot: IotImg,
  entertainment: EntertainmentImg,
  rental: RentalImg,
};

export default function IndustriesItem({ industry, width, height }) {
  const { title, description, key } = industry;
  return (
    <ItemCard
      $padding="32px 0 16px"
      $flexDirection="column"
      $justifyContent="space-between"
      $width={width}
      $height={height}
      $useBorderAnimation
      className="industry-item"
    >
      <H4 $useMedia $mediaSmallFontSize="14px" className="industry-card-title">
        {title}
      </H4>
      <img src={images[key]} alt={title} className="industry-icon" />
      <div className="animation-bg">
        <Paragraph $align="center" $color="#F3F8FA" $mediaSmallFontSize="14px">
          {description}
        </Paragraph>
      </div>
    </ItemCard>
  );
}
