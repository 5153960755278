import { Link } from 'react-router-dom';
import Joi from 'joi';
import Form from './Form';
import Project_Management_Deviark from '../../assets/Project_Management_Deviark.pdf';
import TextLarge from '../typography/TextLarge';

class GetInfoForm extends Form {
  state = {
    data: {
      fullName: '',
      email: '',
    },
    errors: {},
    stage: {
      sending: false,
      success: false,
      error: false,
    },
  };

  // Validation requirements
  schemaProps = (propName) => {
    const schemaProps = {
      fullName: Joi.string().required().label('Full Name'),
      email: Joi.string()
        .email({ tlds: { allow: false } })
        .message('Invalid email')
        .required()
        .label('Email'),
    };

    return propName ? schemaProps[propName] : schemaProps;
  };

  schema = Joi.object(this.schemaProps());

  // Submiting to server
  doSubmit = () => {
    console.log('Submit');

    const data = { ...this.state.data };
    const { REACT_APP_TEMPLATE_GET_PRESENTATION_FORM } = process.env; // ID of Template for Contact Form
    return { data, templateId: REACT_APP_TEMPLATE_GET_PRESENTATION_FORM };
  };

  render() {
    const handleDownload = () => {
      const link = document.createElement('a');
      link.download = 'Project_Management_Deviark';

      link.href = Project_Management_Deviark;

      link.click();
    };

    return (
      <form className="form form--contact" onSubmit={this.handleSubmit}>
        <div className="form__fields">
          <TextLarge $align="left" className="content-form__title">
            Please fill out the form, and then you’ll be able to download our
            presentation.
          </TextLarge>

          {this.renderInput('fullName', 'Full Name', 'Your Full Name')}
          {this.renderInput('email', 'Email', 'your@email.com')}

          <div className="text-tiny">
            *By sending this, you confirm acknowledgment of our{' '}
            <Link to="/privacy-policy" className="text-tiny__link">
              Privacy Policy
            </Link>
          </div>
        </div>

        {this.renderButton('GET PRESENTATION', handleDownload)}

        {this.renderMessage()}
      </form>
    );
  }
}

export default GetInfoForm;
